/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
import { encGet } from "../http/request";
import { AppDispatch } from "../../configureStore";
import HostConnection, { IHostConnection } from "../../utils/em-host";
import execute from "../http/execute";
import success from "../http/success";
import failure from "../http/failure";
import sandbox from "../../sandbox";
import { ERROR_MESSAGE } from "./status";
// eslint-disable-next-line import/no-cycle
import { BorrowerStatus } from "./borrowers";

export interface TransactionOrigin {
    partnerAccessToken: string;
    id: string;
    context: string;
}

export interface CreateRequest {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    employeeEmail: string;
    version: string;
    encompassId?: string;
}

export interface IEncBorrower {
    id: string;
    taxIdentificationIdentifier?: string;
    firstName?: string;
    fullNameWithSuffix?: string;
    lastName?: string;
    emailAddressText?: string;
}

export interface IEncApplication {
    id: string;
    borrower: IEncBorrower;
    coborrower: IEncBorrower;
}

export interface IEncLoan {
    applications: IEncApplication[];
    loanNumber: string;
    id: string;
}

export interface IEncPOC {
    name: string;
    email: string;
    phone: string;
    personas: string[];
}

export interface IEncOriginatingParty {
    name: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    pointOfContact: IEncPOC;
}

export interface IOriginCustomer {
    id: string;
    encompassId: string;
    status: BorrowerStatus;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    invitedBy: string;
    invitedByName: string;
    invitedOn: string;
    acceptedOn: string;
}

export interface APIOrigin {
    customers: IOriginCustomer[];
    origin: {
        id: string;
        entityRef: {
            entityId: string;
            entityType: string;
        };
        loanFormat: string;
        loan?: IEncLoan;
        interfaceUrl: string;
        product: {
            id: string;
            partnerId: string;
            name: string;
        };
        environment: string;
        originatingParty: IEncOriginatingParty;
        identity: {
            hash: string;
        };
    };
}

const initialState: {
    host?: IHostConnection
    origin?: APIOrigin;
    transactionOrigin?: TransactionOrigin;
    noAccess: boolean;
} = {
    noAccess: false,
};

export const GET_HOST_REQUEST = 'encompass/GET_HOST_REQUEST';
export const GET_HOST_SUCCESS = 'encompass/GET_HOST_SUCCESS';
export const GET_HOST_FAILURE = 'encompass/GET_HOST_FAILURE';

export const GET_TXN_ORIGIN_REQUEST = 'encompass/GET_TXN_ORIGIN_REQUEST';
export const GET_TXN_ORIGIN_SUCCESS = 'encompass/GET_TXN_ORIGIN_SUCCESS';
export const GET_TXN_ORIGIN_FAILURE = 'encompass/GET_TXN_ORIGIN_FAILURE';

export const GET_ORIGIN_REQUEST = 'encompass/GET_ORIGIN_REQUEST';
export const GET_ORIGIN_SUCCESS = 'encompass/GET_ORIGIN_SUCCESS';
export const GET_ORIGIN_FAILURE = 'encompass/GET_ORIGIN_FAILURE';

export const CREATE_TRANSACTION_REQUEST = 'encompass/CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'encompass/CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'encompass/CREATE_TRANSACTION_FAILURE';

type ActionParams =
    | { type: typeof GET_HOST_SUCCESS; payload: Payload; data: IHostConnection }
    | { type: typeof GET_HOST_FAILURE; payload: Payload; data: any }
    | { type: typeof GET_TXN_ORIGIN_SUCCESS; payload: Payload; data: TransactionOrigin }
    | { type: typeof GET_ORIGIN_SUCCESS; payload: Payload; data: APIOrigin };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_HOST_SUCCESS:
            sandbox.set('host', action.data);
            return {
                ...state,
                host: action.data,
            };
        case GET_HOST_FAILURE:
            sandbox.set('host', undefined);
            return {
                ...state,
            };
        case GET_TXN_ORIGIN_SUCCESS:
            return {
                ...state,
                transactionOrigin: action.data,
            };
        case GET_ORIGIN_SUCCESS:
            return {
                ...state,
                origin: action.data,
            };
        default:
            return state;
    }
}

export const getHost = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    dispatch({
        type: GET_HOST_REQUEST,
        payload,
    });

    HostConnection()
        .then((res) => dispatch({
            type: GET_HOST_SUCCESS,
            data: res,
        }))
        .catch((err) => dispatch({
            type: GET_HOST_FAILURE,
            payload: err,
        }));
};

export const getTransactionOrigin = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.getTransactionOrigin()
        .then((res) => dispatch({
            type: GET_TXN_ORIGIN_SUCCESS,
            data: res,
        }))
        .catch((err) => {
            dispatch({
                type: GET_TXN_ORIGIN_FAILURE,
                payload: err,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: "Something went wrong getting your session.",
            });
        });

    dispatch({
        type: GET_TXN_ORIGIN_REQUEST,
        payload,
    });
};

export const refreshOrigin = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.refreshOrigin()
        .then((res) => {
            dispatch({
                type: GET_TXN_ORIGIN_SUCCESS,
                data: res,
            });
            if (payload?.onSuccess) {
                payload.onSuccess(res);
            }
        })
        .catch((err) => {
            dispatch({
                type: GET_TXN_ORIGIN_FAILURE,
                payload: err,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: "Something went wrong refreshing your session.",
            });
        });

    dispatch({
        type: GET_TXN_ORIGIN_REQUEST,
        payload,
    });
};

export const closeTransaction = () => (_dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    host.close().then().catch();
};

export const sendEncompassRiaRequest = (payload: Payload<CreateRequest>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    dispatch({
        type: CREATE_TRANSACTION_REQUEST,
    });
    if (!payload.input) {
        dispatch({ type: CREATE_TRANSACTION_FAILURE });
        return;
    }
    host.createTransaction({
        request: {
            type: 'Submit RIA',
            scope: {
                type: "INDIVIDUAL",
                id: payload.input.encompassId,
            },
            options: {
                id: payload.input?.id ?? payload.input?.encompassId,
                firstName: payload.input.firstName,
                lastName: payload.input.lastName,
                email: payload.input.email,
                employeeEmail: payload.input.employeeEmail,
                termsVersion: payload.input.version,
                isResend: false,
            },
        },
    }).then(() => {
        dispatch({
            type: CREATE_TRANSACTION_SUCCESS,
            payload,
        });
        setTimeout(() => {
            window.location.reload();
        }, 4000);
        if (payload?.onSuccess) payload.onSuccess({});
    }).catch(() => {
        dispatch({ type: CREATE_TRANSACTION_SUCCESS });
        if (payload?.onError) payload.onError({});
    });
};

export const resendEncompassRiaRequest = (payload: Payload<CreateRequest>) => (dispatch: AppDispatch) => {
    const host: IHostConnection = sandbox.get('host');
    dispatch({ type: CREATE_TRANSACTION_REQUEST });
    if (!payload.input) {
        dispatch({ type: CREATE_TRANSACTION_FAILURE });
        return;
    }
    host.createTransaction({
        request: {
            type: 'Submit RIA',
            scope: {
                type: "INDIVIDUAL",
                id: payload.input.encompassId,
            },
            options: {
                id: payload.input?.id ?? payload.input?.encompassId,
                firstName: payload.input.firstName,
                lastName: payload.input.lastName,
                email: payload.input.email,
                employeeEmail: payload.input.employeeEmail,
                termsVersion: payload.input.version,
                isResend: true,
            },
        },
    }).then(() => {
        dispatch({
            type: CREATE_TRANSACTION_SUCCESS,
            payload,
        });
        if (payload?.onSuccess) payload.onSuccess({});
    }).catch(() => {
        dispatch({ type: CREATE_TRANSACTION_SUCCESS });
        if (payload?.onError) payload.onError({});
    });
};

export const getOrigin = (payload: Payload<TransactionOrigin>) => (dispatch: AppDispatch) => {
    execute(encGet, '/ria/encompass', undefined, payload.headers)
        .then(success(dispatch, GET_ORIGIN_SUCCESS, payload))
        .catch(failure(dispatch, GET_ORIGIN_FAILURE, payload, 'Failure originating service.'));

    dispatch({
        type: GET_ORIGIN_REQUEST,
        payload,
    });
};

export default reducer;
