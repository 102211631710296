import { useLayoutEffect, useState } from 'react';
import useEventListener from './useEventListener';

interface WindowSize {
    width: number;
    height: number;
    isMobile: boolean;
    isSmall: boolean;
}

function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
        isMobile: false,
        isSmall: false,
    });

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth <= 600,
            isSmall: window.innerWidth <= 1500,
        });
    };

    useEventListener('resize', handleSize);

    useLayoutEffect(() => {
        handleSize();
    }, []);

    return windowSize;
}

export default useWindowSize;
