import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Box, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { IRoute } from "../../interfaces";
import globalStyles from "../../globalStyles";
import { AppBar } from '../templates/Header';
import IconMenu from '../molecules/IconMenu';
import Profile from './Profile';
import { AppDispatch, RootState } from '../../configureStore';
import { Institution, Me, setInstitution } from '../../redux/reducer/auth';

interface AppHeaderParams {
    open: boolean;
    active?: IRoute;
}

const AppHeader = ({
    active,
    open,
}: AppHeaderParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const me = useSelector<RootState, Me | undefined>(({ auth }) => auth.me);
    const loaded = useSelector<RootState, boolean>(({ status }) => status.success['auth/GET_INSTITUTIONS']);

    const localStorageInstitutionId = localStorage.getItem("InstitutionId");

    const institutions = useSelector<RootState, Institution[]>(({ auth }) => auth.institutions);
    const [institutionId, setInstitutionId] = useState<string>(localStorageInstitutionId ?? me?.institutionId ?? "");

    useEffect(() => {
        if (institutionId === '' && me?.fiCode) setInstitutionId(me.fiCode);
    }, [me]);

    return (
        <AppBar
            position="fixed"
            open={open}
            sx={globalStyles.white}
        >
            <Toolbar sx={globalStyles.growBetween}>
                <Box sx={globalStyles.flexCenter}>
                    <Box
                        component="img"
                        sx={{
                            height: 40,
                            width: 40,
                        }}
                        alt="Halcyon Still Water"
                        src="/logo.png"
                    />
                    <Box
                        sx={[globalStyles.flexCenter, globalStyles.col, globalStyles.ml1]}
                    >
                        <Typography
                            variant="h6"
                            color="#5fb7ab"
                            noWrap
                            textTransform="uppercase"
                            fontWeight={600}
                            lineHeight={1}
                        >
                            Halcyon
                        </Typography>
                        <Typography
                            variant="h6"
                            color="#055FFC"
                            noWrap
                            textTransform="uppercase"
                            fontWeight={600}
                            lineHeight={1}
                        >
                            RIA
                        </Typography>
                    </Box>
                    <HorizontalRuleIcon
                        sx={{ transform: 'rotate(90deg)' }}
                        color="disabled"
                    />
                    <Typography
                        variant="h6"
                        fontWeight="600"
                        fontSize="18px"
                        color="#000000"
                    >
                        {active?.name || ''}
                    </Typography>
                </Box>

                <Box>
                    {
                        (me?.level === 0 && loaded) && (
                            <Select
                                value={institutionId}
                                onChange={(e: SelectChangeEvent) => {
                                    setInstitutionId(e.target.value);
                                    localStorage.setItem("InstitutionId", e.target.value);
                                    dispatch(setInstitution(e.target.value));
                                }}
                                sx={{ height: 48, width: 256, textAlign: 'left', marginRight: 2 }}
                                MenuProps={{ sx: { maxHeight: 512 } }}
                            >
                                {
                                    institutions.map(({ id: instId, name }) => (
                                        <MenuItem key={`inst-select-${instId}`} value={instId}>{name}</MenuItem>
                                    ))
                                }
                            </Select>
                        )
                    }
                    {me && (
                        <IconMenu
                            name="AccountCircleOutlined"
                            imageUrl={me?.imageUrl}
                            item={(<Profile user={me} />)}
                        />
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
