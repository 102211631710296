import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import riaText, { IRIA } from "../../utils/riaText";
import { RootState } from "../../configureStore";
import RIAForm from "../molecules/RIAForm";
import Button from "../atoms/Button";

interface InvitationModalParams {
    open: boolean;
    handleClose: () => void;
    onSubmit: (
        _: {
            firstName: string;
            lastName: string;
            email: string;
            id?: string;
            version: string;
        },
        _r?: boolean,
        _ce?: boolean,
        _onDone?: () => void,
    ) => void;
}

const InvitationModal = ({
    open,
    handleClose,
    onSubmit,
}: InvitationModalParams) => {
    const companyName = useSelector<RootState, string>(({ encompass, auth }) => (auth?.institution?.name ?? encompass.origin?.origin?.originatingParty?.name ?? ''));
    const [step, setStep] = useState<number>(0);
    const [read, setRead] = useState<boolean>(false);

    const ria = useMemo<IRIA>(() => riaText(companyName), [companyName]);

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
        if (bottom) {
            setTimeout(() => {
                setRead(true);
            }, 500);
        }
    };

    useEffect(() => {
        setRead(false);
        setStep(0);
    }, [open]);

    const handleSubmit = (
        form: {
            firstName: string;
            lastName: string;
            email: string;
            id?: string;
            encompassId?: string;
        },
        isResend?: boolean,
        clientExists?: boolean,
        onDone?: () => void,
    ) => {
        onSubmit(
            {
                ...form,
                version: ria.version,
            },
            isResend,
            clientExists,
            onDone,
        );
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setStep(0);
                setRead(false);
                handleClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle id="scroll-dialog-title">Loan Officer Acknowledgement</DialogTitle>
            <DialogContent
                dividers
                onScroll={handleScroll}
            >
                {
                    !step
                        ? ria.text.map((text, idx) => (
                            <DialogContentText
                                id="scroll-dialog-description"
                                tabIndex={-1}
                                key={`ria-text-${idx}`}
                                style={{ marginBottom: 8 }}
                            >
                                {text}
                            </DialogContentText>
                        ))
                        : (
                            <RIAForm
                                onSubmit={handleSubmit}
                                onClose={() => {
                                    setStep(0);
                                    setRead(false);
                                    handleClose();
                                }}
                            />
                        )
                }
            </DialogContent>
            {
                !step && (
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setStep(0);
                                setRead(false);
                                handleClose();
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!read}
                            onClick={() => { setStep(1); }}
                            variant="contained"
                        >
                            I Agree
                        </Button>
                    </DialogActions>
                )
            }
        </Dialog>
    );
};

export default InvitationModal;
