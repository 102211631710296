import { IPublicClientApplication } from "@azure/msal-browser";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Button from "../atoms/Button";
import globalStyles from "../../globalStyles";
import Icon from "../atoms/Icon";
import { RootState } from "../../configureStore";

interface NoAccessParams {
    instance?: IPublicClientApplication;
    close?: () => void;
}

const NoAccess = ({
    instance,
    close,
}: NoAccessParams) => {
    const userNotFound = useSelector<RootState, boolean>(({ auth: { userNotFound: unf } }) => unf);

    return (
        <Box
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                minHeight: "100vh",
                color: "#000133",
                flexDirection: 'column',
            }}
        >
            <Button
                type="button"
                variant="text"
                sx={[{ textTransform: 'none', alignSelf: 'flex-end', position: 'absolute', top: 1, right: 1, margin: 1 }]}
                onClick={() => {
                    instance ? instance.logoutRedirect() : close ? close() : () => { };
                }}
            >
                {instance ? 'Sign out' : 'Close'}
            </Button>
            {userNotFound ? (
                <>
                    <Typography sx={globalStyles.my1} variant="h3">Account Not Found!</Typography>
                    <Typography sx={globalStyles.my1}>It seems your account has not been created. Please contact your administrator to add your account.</Typography>
                </>
            ) : (
                <>
                    <Typography sx={globalStyles.my1} variant="h3">Activate Halcyon&apos;s RIA Service!</Typography>
                    <Typography sx={globalStyles.my1}>It seems you haven&apos;t subscribed to our RIA service yet. To enjoy the full benefits and activate this service, please contact Halcyon.</Typography>
                    <Box sx={[globalStyles.flexCenterCenter, globalStyles.my1]}><Icon name="Email" color="primary" /><Typography variant="h6">Email us at: <a href="mailto:info@halcyonsw.com">info@halcyonsw.com</a></Typography></Box>
                    <Typography sx={globalStyles.my1}>We&apos;re here to assist you!</Typography>
                </>
            )}
        </Box>
    );
};

export default NoAccess;
