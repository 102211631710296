import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { Borrower, BorrowerStatus, SET_ACTIVE_BORROWER } from "../../redux/reducer/borrowers";
import InviteActionButton from "./InviteActionButton";
import globalStyles from "../../globalStyles";
import IconMenu from "./IconMenu";
import { AppDispatch } from "../../configureStore";

interface ActionColumnParams {
    borrower: Borrower;
    isEncompass: boolean;
    inviteDisabled: boolean;
    openDialog: () => void;
    openEdit: () => void;
    openDelete: () => void;
}

const ActionColumn = ({
    borrower,
    isEncompass,
    inviteDisabled,
    openDialog,
    openEdit,
    openDelete,
}: ActionColumnParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (isEncompass ? (
        <InviteActionButton
            disabled={inviteDisabled}
            item={borrower}
            openDialog={openDialog}
        />
    ) : (
        <Box sx={globalStyles.flexCenterAround}>
            <InviteActionButton
                disabled={inviteDisabled}
                item={borrower}
                openDialog={openDialog}
            />
            {
                borrower.status !== BorrowerStatus.ACCEPTED ? (
                    <IconMenu
                        items={[
                            {
                                item: 'Edit',
                                onClick: () => {
                                    dispatch({
                                        type: SET_ACTIVE_BORROWER,
                                        data: borrower,
                                    });
                                    openEdit();
                                },
                            },
                            {
                                item: 'Delete',
                                onClick: () => {
                                    dispatch({
                                        type: SET_ACTIVE_BORROWER,
                                        data: borrower,
                                    });
                                    openDelete();
                                },
                            },
                        ]}
                    />
                ) : (
                    <div />
                )
            }
        </Box>
    ));
};

export default ActionColumn;
