import { CSSProperties, useMemo, useState } from "react";
import {
    Box,
    MenuItem,
    Table as MuiTable,
    Paper,
    Select,
    SelectChangeEvent,
    Skeleton,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    colors,
    useTheme,
} from "@mui/material";
import { SearchOff } from "@mui/icons-material";
import { IColumnType } from "../../utils/interface";
import TableFilter from "../molecules/TableFilter";
import globalStyles from "../../globalStyles";

interface TableParams {
    columns: IColumnType[];
    data: any[]
    keyIndex?: string;
    keyPrefix?: string;
    loading?: boolean;
    style?: CSSProperties;
    className?: string;
    IsEmptyComponent?: string | React.ReactElement;
    emptyComponentText?: string;
    pagination?: boolean;
}

const styles: {
    [key: string]: CSSProperties;
} = {
    tableCell: {
        borderRightWidth: 1,
        borderRightColor: colors.grey['100'],
        borderRightStyle: 'solid',
        textAlign: 'center',
    },
    headerCell: {
        fontWeight: '600',
        borderRight: `1px solid ${colors.grey['100']}`,
        textAlign: 'center',
    },
};

const dOnFilter = () => { };

const Table = ({
    columns,
    data,
    keyIndex = 'id',
    keyPrefix,
    loading = false,
    style = {},
    className = '',
    emptyComponentText = "No items found.",
    IsEmptyComponent = (
        <div className="flex-center-center col">
            <SearchOff />
            <p>
                {emptyComponentText}
            </p>
        </div>
    ),
    pagination = true,
}: TableParams) => {
    const [page, setPage] = useState(0);
    const [take, setTake] = useState(10);
    const theme = useTheme();

    const header = useMemo(() => (
        <TableHead>
            <TableRow>
                {columns.map(({
                    title,
                    filterItems,
                    headStyles,
                    filterRender,
                    onFilter,
                }, idx) => (
                    <TableCell
                        sx={[styles.headerCell, headStyles || {}]}
                        key={`${keyPrefix ? `${keyPrefix}-` : ''}head-${idx}`}
                    >
                        {
                            loading
                                ? (<Skeleton variant="rectangular" />)
                                : filterItems ? (
                                    <>
                                        {title}
                                        <TableFilter
                                            id={title}
                                            items={filterItems || []}
                                            filterRender={filterRender}
                                            onFilter={onFilter || dOnFilter}
                                        />
                                    </>
                                )
                                    : (

                                        title
                                    )
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    ), [
        columns,
        loading,
        keyPrefix,
    ]);

    const rows = useMemo(() => (loading
        ? (<TableRow>{columns.map((_, i) => <TableCell key={`${keyPrefix ? `${keyPrefix}-` : ''}skeleton-${i}`}><Skeleton variant="rectangular" /></TableCell>)}</TableRow>)
        : data.map((datum: any) => (
            <TableRow key={`${keyPrefix ? `${keyPrefix}-` : ''}${datum[keyIndex]}-row`}>
                {
                    columns.map(({ key, render, cellStyles }, cIdx) => (
                        <TableCell
                            key={`${keyPrefix ? `${keyPrefix}-` : ''}${keyIndex}-${cIdx}`}
                            sx={[styles.tableCell, cellStyles || {}]}
                        >
                            {render ? render(datum[key], datum) : datum[key]}
                        </TableCell>
                    ))
                }
            </TableRow>
        )).slice(page * take, (page * take) + take)), [
        data,
        keyPrefix,
        keyIndex,
        columns,
        loading,
        page,
        take,
    ]);

    const pages = useMemo(() => {
        let more = false;
        let less = false;
        const arr = Array.from(Array(Math.ceil(data.length / (take || 1)))).map((_, num) => (
            <div
                key={`table-page-${num}`}
                onClick={() => setPage(num)}
                style={{
                    fontWeight: num === page ? '900' : undefined,
                    color: num === page ? theme.palette.primary.main : theme.palette.primary.light,
                    ...globalStyles.px2,
                    fontSize: num === page ? 20 : 14,
                    cursor: 'pointer',
                }}
            >
                {num + 1}
            </div>
        )).filter((_, num) => {
            if (num > page + 2) more = true;
            if (num < page - 2) less = true;
            return (num >= page - 2 && num <= page + 2);
        });
        if (less) arr.unshift(<div key="table-less">...</div>);
        if (more) arr.push(<div key="table-more">...</div>);
        return arr;
    }, [take, page, data]);

    return (
        <TableContainer
            component={Paper}
            style={style}
            className={className}
        >
            <MuiTable>
                {header}
                <TableBody>
                    {
                        (!loading && data.length === 0) ? (
                            <TableRow className="flex-center-center">
                                <TableCell style={{ textAlign: 'center' }} colSpan={24}>
                                    {IsEmptyComponent}
                                </TableCell>
                            </TableRow>
                        ) : rows
                    }
                </TableBody>
                {
                    pagination && (
                        <TableFooter sx={globalStyles.px1}>
                            <TableRow>
                                <TableCell colSpan={1} sx={{ textAlign: 'center' }}>
                                    <p style={{ color: colors.grey['800'] }}>
                                        <span style={{ color: colors.grey['500'] }}>Showing {Array.isArray(rows) ? rows.length : 0} of</span> {data.length}
                                    </p>
                                </TableCell>
                                <TableCell colSpan={columns.length - 2} sx={{ textAlign: 'center' }}>
                                    <Box sx={[globalStyles.flex, globalStyles.flexCenterCenter]}>
                                        {pages}
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Select
                                        value={take.toString()}
                                        onChange={(e: SelectChangeEvent) => {
                                            setTake(Number(e.target.value));
                                        }}
                                    >
                                        <MenuItem key="table-5" value={5}>5</MenuItem>
                                        <MenuItem key="table-10" value={10}>10</MenuItem>
                                        <MenuItem key="table-50" value={50}>50</MenuItem>
                                        <MenuItem key="table-100" value={100}>100</MenuItem>
                                        <MenuItem key="table-250" value={250}>250</MenuItem>
                                        <MenuItem key="table-500" value={500}>500</MenuItem>
                                    </Select>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    )
                }
            </MuiTable>
        </TableContainer>
    );
};

export default Table;
