/* eslint-disable no-case-declarations */
/* eslint-disable import/no-cycle */
/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
import { AppDispatch } from "../../configureStore";
import { IInvite } from "../../utils/interface";
import execute from "../http/execute";
import failure from "../http/failure";
import { del, get, patch, post, put } from "../http/request";
import success from "../http/success";
import { APIOrigin, CREATE_TRANSACTION_SUCCESS, CreateRequest, GET_ORIGIN_SUCCESS } from "./encompass";

const delayFixed = false;

// eslint-disable-next-line no-shadow
export enum BorrowerStatus {
    ACCEPTED = "ACCEPTED",
    NOT_INVITED = "NOT_INVITED",
    NOT_ACCEPTED = "NOT_ACCEPTED",
    UNSUBSCRIBED = "UNSUBSCRIBED",
}

export interface Filters {
    status: BorrowerStatus[]
    name: string[];
    email: string[];
    invitedBy: string[];
}

export interface Borrower {
    id: string;
    encompassId?: string;
    status: BorrowerStatus;
    name: string
    firstName?: string;
    lastName?: string;
    email: string;
    invitedBy?: string;
    invitedByName?: string;
    invitedOn?: Date;
    acceptedOn?: Date;
    imageUrl?: string;
}

export const GET_BORROWERS_REQUEST = 'borrowers/GET_BORROWERS_REQUEST';
export const GET_BORROWERS_SUCCESS = 'borrowers/GET_BORROWERS_SUCCESS';
export const GET_BORROWERS_FAILURE = 'borrowers/GET_BORROWERS_FAILURE';

export const GET_CUSTOMERS_EXCEL_REQUEST = 'borrowers/GET_CUSTOMERS_EXCEL_REQUEST';
export const GET_CUSTOMERS_EXCEL_SUCCESS = 'borrowers/GET_CUSTOMERS_EXCEL_SUCCESS';
export const GET_CUSTOMERS_EXCEL_FAILURE = 'borrowers/GET_CUSTOMERS_EXCEL_FAILURE';

export const INVITE_BORROWER_REQUEST = 'borrowers/INVITE_BORROWER_REQUEST';
export const INVITE_BORROWER_SUCCESS = 'borrowers/INVITE_BORROWER_SUCCESS';
export const INVITE_BORROWER_FAILURE = 'borrowers/INVITE_BORROWER_FAILURE';

export const REINVITE_BORROWER_REQUEST = 'borrowers/REINVITE_BORROWER_REQUEST';
export const REINVITE_BORROWER_SUCCESS = 'borrowers/REINVITE_BORROWER_SUCCESS';
export const REINVITE_BORROWER_FAILURE = 'borrowers/REINVITE_BORROWER_FAILURE';

export const DELETE_CUSTOMER_REQUEST = 'borrowers/DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'borrowers/DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'borrowers/DELETE_CUSTOMER_FAILURE';

export const EDIT_CUSTOMER_REQUEST = 'borrowers/EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'borrowers/EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'borrowers/EDIT_CUSTOMER_FAILURE';

export const SET_ACTIVE_BORROWER = 'borrowers/SET_ACTIVE_BORROWER';
export const CLEAR_ACTIVE_BORROWER = 'borrowers/CLEAR_ACTIVE_BORROWER';

export const SET_BORROWER_FILTERS_EMAIL = 'borrowers/SET_BORROWER_FILTERS_EMAIL';
export const SET_BORROWER_FILTERS_INVITED = 'borrowers/SET_BORROWER_FILTERS_INVITED';
export const SET_BORROWER_FILTERS_NAME = 'borrowers/SET_BORROWER_FILTERS_NAME';
export const SET_BORROWER_FILTERS_STATUS = 'borrowers/SET_BORROWER_FILTERS_STATUS';

const initialState: {
    borrowers: Borrower[];
    activeBorrower?: Borrower;
    filters: Filters;
} = {
    borrowers: [],
    filters: {
        email: [],
        invitedBy: [],
        name: [],
        status: [],
    },
};

type ActionParams =
    | { type: typeof GET_ORIGIN_SUCCESS; payload: Payload; data: APIOrigin; }
    | { type: typeof GET_BORROWERS_SUCCESS; payload: Payload; data: Borrower[]; }
    | { type: typeof SET_ACTIVE_BORROWER; data: Borrower; }
    | { type: typeof CLEAR_ACTIVE_BORROWER; }
    | { type: typeof SET_BORROWER_FILTERS_EMAIL; payload: string[]; }
    | { type: typeof SET_BORROWER_FILTERS_INVITED; payload: string[]; }
    | { type: typeof SET_BORROWER_FILTERS_NAME; payload: string[]; }
    | { type: typeof SET_BORROWER_FILTERS_STATUS; payload: BorrowerStatus[]; }
    | { type: typeof INVITE_BORROWER_SUCCESS; data: any; input: any; }
    | { type: typeof DELETE_CUSTOMER_SUCCESS; input: { id: string; } }
    | { type: typeof CREATE_TRANSACTION_SUCCESS; payload: Payload<CreateRequest> }
    | {
        type: typeof EDIT_CUSTOMER_SUCCESS; input: {
            firstName: string;
            lastName: string;
            email: string;
            id?: string | undefined;
        }
    };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case CREATE_TRANSACTION_SUCCESS:
            if (delayFixed) {
                const tempBor: Borrower[] = JSON.parse(JSON.stringify(state.borrowers));
                const indx = tempBor.findIndex(({ id }) => id === action.payload.input?.id);

                tempBor[indx].invitedOn = new Date();
                tempBor[indx].status = BorrowerStatus.NOT_ACCEPTED;
                tempBor[indx].email = action.payload.input?.email ?? '';
                return {
                    ...state,
                    borrowers: tempBor,
                };
            }
            return state;
        case GET_ORIGIN_SUCCESS:
            if (action.data?.customers) {
                const encBorrowers = action.data?.customers.map((app) => {
                    const bing: Borrower = {
                        id: app.id,
                        encompassId: app.encompassId,
                        email: app.email ?? '',
                        firstName: app.firstName,
                        lastName: app.lastName,
                        name: `${app.firstName} ${app.lastName}`,
                        status: app.status,
                        acceptedOn: app.acceptedOn ? new Date(app.acceptedOn) : undefined,
                        invitedBy: app.invitedBy,
                        invitedByName: app.invitedByName,
                        invitedOn: app.invitedOn ? new Date(app.invitedOn) : undefined,
                    };
                    return bing;
                });
                return {
                    ...state,
                    borrowers: encBorrowers,
                };
            }
            return {
                ...state,
                borrowers: [],
            };
        case GET_BORROWERS_SUCCESS:
            return {
                ...state,
                borrowers: action.data,
            };
        case SET_ACTIVE_BORROWER:
            return {
                ...state,
                activeBorrower: action.data,
            };
        case CLEAR_ACTIVE_BORROWER:
            return {
                ...state,
                activeBorrower: undefined,
            };
        case SET_BORROWER_FILTERS_EMAIL:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    email: action.payload,
                },
            };
        case SET_BORROWER_FILTERS_INVITED:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    invitedBy: action.payload,
                },
            };
        case SET_BORROWER_FILTERS_NAME:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    name: action.payload,
                },
            };
        case SET_BORROWER_FILTERS_STATUS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    status: action.payload,
                },
            };
        case INVITE_BORROWER_SUCCESS:
            if (action.input.clientExists) {
                try {
                    const found = state.borrowers.findIndex((v) => v.id === action.data.responseData.id);
                    if (found >= 0) {
                        const stateCopy = JSON.parse(JSON.stringify(state.borrowers));
                        stateCopy[found] = action.data.responseData;
                        return {
                            ...state,
                            borrowers: stateCopy,
                        };
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            if (action.data) {
                return {
                    ...state,
                    borrowers: [action.data?.responseData ?? action.data, ...state.borrowers],
                };
            }
            return state;
        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                borrowers: state.borrowers.filter(({ id }) => id !== action.input.id),
            };
        case EDIT_CUSTOMER_SUCCESS:
            const edited: Borrower[] = JSON.parse(JSON.stringify(state.borrowers));
            const editedIndex = edited.findIndex((brw) => brw.id === action.input.id);
            edited[editedIndex].firstName = action.input.firstName;
            edited[editedIndex].lastName = action.input.lastName;
            if (edited[editedIndex].email !== action.input.email) {
                edited[editedIndex].invitedOn = new Date();
            }
            edited[editedIndex].email = action.input.email;
            return {
                ...state,
                borrowers: edited,
            };
        default: {
            return state;
        }
    }
}

export const getBorrowers = (payload?: Payload) => (dispatch: AppDispatch) => {
    execute(get, '/ria/customers')
        .then(success(dispatch, GET_BORROWERS_SUCCESS, payload))
        .catch(failure(dispatch, GET_BORROWERS_FAILURE, payload, 'Failure retrieving clients.'));

    if (!payload?.noLoad) {
        dispatch({
            type: GET_BORROWERS_REQUEST,
            payload,
        });
    }
};

export const inviteCustomer = (payload?: Payload<IInvite>) => (dispatch: AppDispatch) => {
    execute(post, '/ria/customers', payload?.input)
        .then(success(dispatch, INVITE_BORROWER_SUCCESS, payload, 'Invitation sent successfully!'))
        .catch(failure(dispatch, INVITE_BORROWER_FAILURE, payload, 'Something went wrong.'));

    dispatch({
        type: INVITE_BORROWER_REQUEST,
        payload,
    });
};

export const resendInvite = (payload?: Payload<IInvite>) => (dispatch: AppDispatch) => {
    execute(patch, '/ria/customers', payload?.input)
        .then(success(dispatch, REINVITE_BORROWER_SUCCESS, payload, 'Invitation sent successfully!'))
        .catch(failure(dispatch, REINVITE_BORROWER_FAILURE, payload, 'Something went wrong.'));

    dispatch({
        type: REINVITE_BORROWER_REQUEST,
        payload,
    });
};

export const getBorrowerExcel = (payload?: Payload) => (dispatch: AppDispatch) => {
    execute(get, '/ria/customers?excel=true')
        .then(success(dispatch, GET_CUSTOMERS_EXCEL_SUCCESS, payload))
        .catch(failure(dispatch, GET_CUSTOMERS_EXCEL_FAILURE, payload));

    dispatch({
        type: GET_CUSTOMERS_EXCEL_REQUEST,
        payload,
    });
};

export const deleteCustomer = (payload: Payload<{ id: string }>) => (dispatch: AppDispatch) => {
    execute(del, `/ria/customers?id=${payload.input?.id}`)
        .then(success(dispatch, DELETE_CUSTOMER_SUCCESS, payload))
        .catch(failure(dispatch, DELETE_CUSTOMER_FAILURE, payload));

    dispatch({
        type: DELETE_CUSTOMER_REQUEST,
        payload,
    });
};

export const editCustomer = (payload: Payload<{
    firstName: string;
    lastName: string;
    email: string;
    id?: string | undefined;
}>) => (dispatch: AppDispatch) => {
    execute(put, '/ria/customers', payload.input)
        .then(success(dispatch, EDIT_CUSTOMER_SUCCESS, payload))
        .catch(failure(dispatch, EDIT_CUSTOMER_FAILURE, payload));

    dispatch({
        type: EDIT_CUSTOMER_REQUEST,
        payload,
    });
};

export const setFiltersName = (payload: string[]) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_BORROWER_FILTERS_NAME,
        payload,
    });
};

export const setFiltersEmail = (payload: string[]) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_BORROWER_FILTERS_EMAIL,
        payload,
    });
};

export const setFiltersInvited = (payload: string[]) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_BORROWER_FILTERS_INVITED,
        payload,
    });
};

export const setFiltersStatus = (payload: BorrowerStatus[]) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_BORROWER_FILTERS_STATUS,
        payload,
    });
};

export default reducer;
