import axios from "axios";

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:7071/api';
const endpointEncompass = process.env.REACT_APP_ENDPOINT_ENCOMPASS || 'http://localhost:7071/api';

export const encGet = (path: string, payload?: any, viewAs?: string, headers?: any) => {
    let params = '';
    if (Object.keys(payload ?? {}).length > 0) {
        params = '?';
        Object.keys(payload).forEach((key) => {
            params += `${key}=${payload[key]}&`;
        });
        params = params.substring(0, params.length - 1);
    }
    return axios.get(`${endpointEncompass}${path}${params}`, {
        ...(viewAs || headers) && {
            headers: {
                ...viewAs && { 'X-View-As': viewAs },
                ...headers,
            },
        },
    });
};

export const getLocal = (path: string, payload?: any, viewAs?: string, headers?: any) => {
    let params = '';
    if (Object.keys(payload ?? {}).length > 0) {
        params = '?';
        Object.keys(payload).forEach((key) => {
            params += `${key}=${payload[key]}&`;
        });
        params = params.substring(0, params.length - 1);
    }
    return axios.get(`http://localhost:7071/api${path}${params}`, {
        ...(viewAs || headers) && {
            headers: {
                ...viewAs && { 'X-View-As': viewAs },
                ...headers,
            },
        },
    });
};

export const get = (path: string, payload?: any, viewAs?: string, headers?: any) => {
    let params = '';
    if (Object.keys(payload ?? {}).length > 0) {
        params = '?';
        Object.keys(payload).forEach((key) => {
            params += `${key}=${payload[key]}&`;
        });
        params = params.substring(0, params.length - 1);
    }
    return axios.get(`${endpoint}${path}${params}`, {
        ...(viewAs || headers) && {
            headers: {
                ...viewAs && { 'X-View-As': viewAs },
                ...headers,
            },
        },
    });
};

export const post = (path: string, payload: any, viewAs?: string, headers?: any) => axios.post(`${endpoint}${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const postLocal = (path: string, payload: any, viewAs?: string, headers?: any) => axios.post(`http://localhost:7071/api${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const postMulti = (path: string, data: FormData, headers?: any) => fetch(
    `${endpoint}${path}`,
    {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY ?? '',
            ...headers,
        },
        body: data,
    },
);

export const patch = (path: string, payload: any, viewAs?: string, headers?: any) => axios.patch(`${endpoint}${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const put = (path: string, payload: any, viewAs?: string, headers?: any) => axios.put(`${endpoint}${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const putLocal = (path: string, payload: any, viewAs?: string, headers?: any) => axios.put(`http://localhost:7071/api${path}`, payload, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const del = (path: string, _: any, viewAs?: string, headers?: any) => axios.delete(`${endpoint}${path}`, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

export const delLocal = (path: string, _: any, viewAs?: string, headers?: any) => axios.delete(`http://localhost:7071/api${path}`, {
    ...(viewAs || headers) && {
        headers: {
            ...viewAs && { 'X-View-As': viewAs },
            ...headers,
        },
    },
});

const request = (dispatch: any, type: string, payload: any) => {
    // eslint-disable-next-line no-param-reassign
    if (payload.onSuccess) delete payload.onSuccess;

    dispatch({
        type,
        payload,
    });
};

export default request;
