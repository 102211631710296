import { Box, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmailEditor from "react-email-editor";
import { Buffer } from 'buffer';

import { AppDispatch, RootState } from "../../configureStore";
import { Institution, Me, updateInstitutionEmail } from "../../redux/reducer/auth";
import LoadingOverlay from "../atoms/LoadingOverlay";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import useWindowSize from "../../utils/hooks/useWindowSize";
import Icon from "../atoms/Icon";

interface EmailEditParams {
}

const EmailEdit = ({
}: EmailEditParams) => {
    const me = useSelector<RootState, Me | undefined>(({ auth }) => auth.me);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/UPDATE_INSTITUTION_EMAIL']);
    const emailEditorRef = useRef<any | null>(null);
    const [isReady, setIsReady] = useState(false);
    const { institutionId } = useParams();
    const institution = useSelector<RootState, Institution | undefined>(({ auth }) => auth.institutions.find(({ id }) => id === institutionId));
    const navigate = useNavigate();
    const { height } = useWindowSize();
    const dispatch = useDispatch<AppDispatch>();

    const onReady = () => {
        setIsReady(true);
    };

    const [subject, setSubject] = useState<string>(institution?.email.subject || 'Connect with an Investment Advisor Today!');

    useEffect(() => { if (me && me?.level > 0) navigate('/'); }, [me]);

    useEffect(() => {
        if (institution?.email?.subject) {
            setSubject(institution.email.subject);
        } else {
            setSubject('Connect with an Investment Advisor Today!');
        }

        if (isReady && institution?.email?.jsonFormat && emailEditorRef?.current?.editor) {
            emailEditorRef.current.editor.loadDesign(JSON.parse(institution.email.jsonFormat));
        }
    }, [isReady, institution]);

    const onClick = () => {
        emailEditorRef.current.editor.exportHtml((editorData: any) => {
            const { design, html } = editorData;
            if (institutionId) {
                dispatch(updateInstitutionEmail({
                    input: {
                        institutionId,
                        jsonFormat: JSON.stringify(design),
                        subject,
                        html: Buffer.from(html).toString('base64'),
                    },
                    onSuccess: () => { navigate('/institutions'); },
                }));
            }
        });
    };

    return (
        <Box>
            <Icon
                name="ArrowBack"
                iconButton={{
                    sx: {
                        position: 'absolute',
                        left: 90,
                        top: 80,
                        zIndex: 100000,
                    },
                }}
                onClick={() => { navigate('/institutions'); }}
            />
            {me ? (
                <Box sx={globalStyles.mt4}>
                    <Typography variant="h4">{institution?.name} - Invitation Email</Typography>
                    <Box sx={globalStyles.flexCenterEnd}>
                        <Button
                            onClick={onClick}
                            variant="contained"
                            loading={loading}
                        >
                            Submit
                        </Button>
                    </Box>
                    <Box className="mt-8 card">
                        <TextField
                            value={subject}
                            fullWidth
                            variant="outlined"
                            size="small"
                            label="Subject"
                            onChange={(e) => { setSubject(e.target.value); }}
                        />
                    </Box>
                    <EmailEditor
                        ref={emailEditorRef}
                        onReady={onReady}
                        minHeight={height * 0.8}
                    />
                </Box>
            ) : <LoadingOverlay />}
        </Box>
    );
};

export default EmailEdit;
