import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../configureStore";
import { closeTransaction } from "../../../redux/reducer/encompass";
import globalStyles from "../../../globalStyles";
import Button from "../../atoms/Button";

interface EncompassHeaderParams {
}

const EncompassHeader = ({
}: EncompassHeaderParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Box sx={[globalStyles.flexCenterEnd, globalStyles.py4]}>
            <Button
                sx={{ marginRight: 1 }}
                variant="contained"
                onClick={() => {
                    window.location.reload();
                }}
            >
                Refresh
            </Button>
            <Button
                variant="contained"
                onClick={() => {
                    dispatch(closeTransaction());
                }}
            >
                Close
            </Button>
        </Box>
    );
};

export default EncompassHeader;
