import { AxiosResponse } from "axios";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import InvitationTable from "./InvitationTable";
import { AppDispatch, RootState } from "../../configureStore";
import { CLEAR_ACTIVE_BORROWER, getBorrowerExcel, getBorrowers, inviteCustomer, resendInvite } from "../../redux/reducer/borrowers";
import InvitationModal from "./InvitationModal";
import { IInvite } from "../../utils/interface";
import { b64toBlob } from "../../utils/text";
import Icon from "../atoms/Icon";
import CreateCustomerModal from "./CreateCustomerModal";
import EditDialog from "./EditDialog";
import DeleteDialog from "./DeleteDialog";

interface InvitationContainerParams {
}

const InvitationContainer = ({
}: InvitationContainerParams) => {
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['borrowers/GET_BORROWERS']);
    const excelLoading = useSelector<RootState, boolean>(({ status }) => status.loading['borrowers/GET_CUSTOMERS_EXCEL']);
    const disabled = useSelector<RootState, boolean>(({ auth }) => (!!auth?.institutionId && auth?.institutionId !== auth?.me?.institutionId));
    const meSuccess = useSelector<RootState, boolean>(({ status }) => status.success['auth/GET_ME']);
    const institutionId = useSelector<RootState, string | undefined>(({ auth }) => auth.institutionId);
    const [open, setOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const submit = (
        form: IInvite,
        isResend?: boolean,
        clientExists?: boolean,
    ) => {
        if (isResend) {
            dispatch(
                resendInvite({
                    input: form,
                    onSuccess: () => { setOpen(false); },
                }),
            );
        } else {
            dispatch(
                inviteCustomer({
                    input: {
                        ...form,
                        clientExists,
                    },
                    onSuccess: () => { setOpen(false); },
                }),
            );
        }
    };

    const createSubmit = (
        form: {
            firstName: string;
            lastName: string;
            email: string;
        },
        _r?: boolean,
        _ce?: boolean,
        onDone?: () => void,
    ) => {
        dispatch(inviteCustomer({
            input: {
                ...form,
                createOnly: true,
            },
            onSuccess: () => {
                setCreateOpen(false);
                onDone && onDone();
            },
        }));
    };

    useEffect(() => {
        if (meSuccess) {
            dispatch(getBorrowers());
            const interval = setInterval(() => {
                dispatch(getBorrowers({ noLoad: true }));
            }, 60000);
            return () => clearInterval(interval);
        }
        return () => { };
    }, [institutionId, meSuccess]);

    const exportToExcel = () => {
        dispatch(getBorrowerExcel({
            onSuccess: (res: AxiosResponse<string>) => {
                const blob = b64toBlob(res.data, 'application/xlsx');
                const blobUrl = URL.createObjectURL(blob);
                const link: any = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute(
                    'download',
                    'Client Details.xlsx',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            },
        }));
    };

    return (
        <Box sx={globalStyles.w100}>
            <InvitationModal
                open={open}
                handleClose={() => {
                    dispatch({ type: CLEAR_ACTIVE_BORROWER });
                    setOpen(false);
                }}
                onSubmit={submit}
            />
            <EditDialog
                open={openEdit}
                handleClose={() => {
                    dispatch({ type: CLEAR_ACTIVE_BORROWER });
                    setOpenEdit(false);
                }}
            />
            <DeleteDialog
                open={openDelete}
                handleClose={() => {
                    dispatch({ type: CLEAR_ACTIVE_BORROWER });
                    setOpenDelete(false);
                }}
            />
            <CreateCustomerModal
                open={createOpen}
                handleClose={() => {
                    dispatch({ type: CLEAR_ACTIVE_BORROWER });
                    setCreateOpen(false);
                }}
                onSubmit={createSubmit}
            />
            <Box sx={[globalStyles.flexCenterBetween, globalStyles.pb1]}>
                <Typography variant="h6">Clients</Typography>
                <Box sx={globalStyles.flexCenterBetween}>
                    <Icon
                        name="AddBoxOutlined"
                        color="action"
                        iconButton={{ disabled }}
                        onClick={() => {
                            setCreateOpen(true);
                        }}
                    />
                    <Button
                        sx={globalStyles.ml1}
                        variant="contained"
                        loading={excelLoading}
                        onClick={() => { exportToExcel(); }}
                    >
                        Export to Excel
                    </Button>
                </Box>
            </Box>
            <InvitationTable
                loading={loading}
                openDialog={() => { setOpen(true); }}
                openEdit={() => { setOpenEdit(true); }}
                openDelete={() => { setOpenDelete(true); }}
            />
        </Box>
    );
};

export default InvitationContainer;
