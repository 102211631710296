import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../organisms/Table";
import { RootState } from "../../configureStore";
import { Institution, Me } from "../../redux/reducer/auth";
import { IColumnType } from "../../utils/interface";
import Icon from "../atoms/Icon";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import InstitutionDialog from "../organisms/InstitutionDialog";

interface InstitutionsParams {
}

const Institutions = ({
}: InstitutionsParams) => {
    const me = useSelector<RootState, Me | undefined>(({ auth }) => auth.me);
    const institutions = useSelector<RootState, Institution[]>(({ auth }) => auth.institutions);
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);
    const [active, setActive] = useState<string>();

    useEffect(() => {
        if (!open) setActive(undefined);
    }, [open]);

    useEffect(() => { if (me && me?.level !== 0) navigate('/'); }, [me]);

    const cols = useMemo<IColumnType<Institution>[]>(() => [
        {
            key: 'name',
            title: 'Name',
        },
        {
            key: 'defaultAdmin',
            title: 'Admin',
        },
        {
            key: 'id',
            title: 'Edit',
            render: (val: string) => (
                <Icon
                    name="Edit"
                    onClick={() => {
                        setActive(val);
                        setOpen(!open);
                    }}
                />
            ),
        },
        {
            key: 'email',
            title: 'Invitation Email',
            render: (_val: string, { id }) => (
                <Icon
                    name="Mail"
                    onClick={() => {
                        navigate(`${id}/email`);
                    }}
                />
            ),
        },
    ], []);

    return (
        <Box>
            <Box sx={[globalStyles.flexCenterBetween, globalStyles.mt4]}>
                <Typography variant="h5">Institutions</Typography>
                <Button
                    onClick={() => { setOpen(true); }}
                    variant="contained"
                >
                    Create
                </Button>
            </Box>
            <Table
                className="mt-4"
                columns={cols}
                keyIndex="id"
                data={institutions}
            />
            <InstitutionDialog
                open={open}
                setOpen={setOpen}
                active={active}
            />
        </Box>
    );
};

export default Institutions;
