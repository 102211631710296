import { Chip, colors, useTheme } from "@mui/material";
import { BorrowerStatus } from "../../redux/reducer/borrowers";

interface StatusChipParams {
    status: BorrowerStatus;
}

const StatusChip = ({
    status,
}: StatusChipParams) => {
    const theme = useTheme();
    return (
        <Chip
            label={status.replace('_', ' ')}
            sx={{
                minWidth: '128px',
                color: status === BorrowerStatus.ACCEPTED
                    ? theme.palette.success.main
                    : status === BorrowerStatus.NOT_ACCEPTED
                        ? theme.palette.warning.main
                        : status === BorrowerStatus.UNSUBSCRIBED
                            ? theme.palette.grey['500']
                            : theme.palette.primary.main,
                fontWeight: 600,
                backgroundColor: status === BorrowerStatus.ACCEPTED
                    ? colors.lightGreen['100']
                    : status === BorrowerStatus.NOT_ACCEPTED
                        ? colors.yellow['100']
                        : status === BorrowerStatus.UNSUBSCRIBED
                            ? colors.grey['100']
                            : colors.lightBlue['100'],
            }}
        />
    );
};

export default StatusChip;
