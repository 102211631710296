import React, { useMemo, useState } from 'react';
import { TextField, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../atoms/Button';
import { Borrower, BorrowerStatus, CLEAR_ACTIVE_BORROWER } from '../../redux/reducer/borrowers';
import { AppDispatch, RootState } from '../../configureStore';
import globalStyles from '../../globalStyles';

const RIAForm = ({
    onSubmit,
    isCreateOnly = false,
    isEdit = false,
    onClose,
}: {
    onSubmit: (
        _: {
            firstName: string;
            lastName: string;
            email: string;
            id?: string;
            encompassId?: string;
        },
        _resend?: boolean,
        _ce?: boolean,
        _onDone?: () => void,
    ) => void;
    isCreateOnly?: boolean;
    isEdit?: boolean;
    onClose?: () => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const activeBorrower = useSelector<RootState, Borrower | undefined>(({ borrowers: { activeBorrower: act } }) => act);
    const disabled = useSelector<RootState, boolean>(({ auth }) => (!!auth?.institutionId && auth?.institutionId !== auth?.me?.institutionId));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['borrowers/INVITE_BORROWER'] || status.loading['borrowers/REINVITE_BORROWER'] || status.loading['encompass/CREATE_TRANSACTION'] || status.loading['borrowers/EDIT_CUSTOMER']);

    const [firstName, setFirstName] = useState<string>(activeBorrower?.firstName ?? '');
    const [lastName, setLastName] = useState<string>(activeBorrower?.lastName ?? '');
    const [email, setEmail] = useState<string>(activeBorrower?.email ?? '');
    const [errors, setErrors] = useState<{
        email?: string;
        lastName?: string;
        firstName?: string;
    }>({});

    const isResend = useMemo<boolean>(() => (Boolean(activeBorrower) && activeBorrower?.status !== BorrowerStatus.NOT_INVITED), [activeBorrower]);
    const isEditable = useMemo<boolean>(() => (!isResend || isEdit), [isResend, isEdit]);
    const validateEmail = (emailAddress: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const newErrors: {
            email?: string;
            lastName?: string;
            firstName?: string;
        } = {};

        if (firstName.trim() === '') {
            newErrors.firstName = 'First name is required';
        }

        if (lastName.trim() === '') {
            newErrors.lastName = 'Last name is required';
        }

        if (!validateEmail(email)) {
            newErrors.email = 'Invalid email address';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            onSubmit(
                {
                    id: activeBorrower?.id,
                    encompassId: activeBorrower?.encompassId,
                    email,
                    firstName,
                    lastName,
                },
                isResend,
                Boolean(activeBorrower && !isResend),
                () => {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    dispatch({ type: CLEAR_ACTIVE_BORROWER });
                },
            );

            dispatch({ type: CLEAR_ACTIVE_BORROWER });
        }
    };

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            variant="outlined"
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName}
                            disabled={!isEditable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            variant="outlined"
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            error={Boolean(errors.lastName)}
                            helperText={errors.lastName}
                            disabled={!isEditable}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            name="email"
                            variant="outlined"
                            fullWidth
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            disabled={!isEditable}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ alignItems: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            loading={loading}
                        >
                            {isEdit
                                ? 'Confirm'
                                : isCreateOnly
                                    ? 'Add Client'
                                    : isResend
                                        ? 'Resend'
                                        : 'Send Invite'}
                        </Button>
                        {
                            onClose && (
                                <Button
                                    variant="outlined"
                                    sx={globalStyles.ml1}
                                    onClick={onClose}
                                >
                                    Close
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default RIAForm;
