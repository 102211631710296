import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../configureStore";
import {
    TransactionOrigin,
    closeTransaction,
    getOrigin,
    getTransactionOrigin,
    refreshOrigin,
    resendEncompassRiaRequest,
    sendEncompassRiaRequest,
} from "../../redux/reducer/encompass";
import { IHostConnection } from "../../utils/em-host";
import EncompassHeader from "../organisms/encompass/EncompassHeader";
import InvitationTable from "../organisms/InvitationTable";
import InvitationModal from "../organisms/InvitationModal";
import NoAccess from "../organisms/NoAccess";

interface EncompassTemplateParams {
}

const EncompassTemplate = ({
}: EncompassTemplateParams) => {
    const [open, setOpen] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();
    const noAccess = useSelector<RootState, boolean>(({ auth }) => auth.noAccess);
    const host = useSelector<RootState, IHostConnection | undefined>(({ encompass }) => encompass.host);
    const loading = useSelector<RootState, boolean>(({ status }) => (status.loading['encompass/GET_TXN_ORIGIN'] || (status.loading['encompass/GET_ORIGIN'] && !status.success['encompass/GET_ORIGIN'])));
    const txnOrgn = useSelector<RootState, TransactionOrigin | undefined>(({ encompass }) => encompass.transactionOrigin);
    const txnSuccess = useSelector<RootState, boolean>(({ status }) => status.success['encompass/GET_TXN_ORIGIN']);
    const employeeEmail = useSelector<RootState, string>(({ encompass }) => encompass.origin?.origin?.originatingParty?.pointOfContact?.email || '');

    useEffect(() => {
        if (host) {
            dispatch(getTransactionOrigin());
        }
    }, [host, dispatch]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(refreshOrigin({
                onSuccess: (res) => {
                    dispatch(getOrigin({
                        headers: res,
                    }));
                },
            }));
        }, 290000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (txnSuccess && txnOrgn) {
            dispatch(getOrigin({
                headers: txnOrgn,
            }));
        }
    }, [
        dispatch,
        txnSuccess,
        txnOrgn,
    ]);

    const submit = (request: any, isResend?: boolean) => {
        if (isResend) {
            dispatch(resendEncompassRiaRequest({
                input: {
                    firstName: request.firstName,
                    lastName: request.lastName,
                    email: request.email,
                    id: request?.id,
                    version: request.version,
                    employeeEmail,
                    encompassId: request.encompassId,
                },
                onSuccess: () => {
                    setOpen(false);
                },
            }));
        } else {
            dispatch(sendEncompassRiaRequest({
                input: {
                    firstName: request.firstName,
                    lastName: request.lastName,
                    email: request.email,
                    id: request?.id,
                    version: request.version,
                    employeeEmail,
                    encompassId: request.encompassId,
                },
                onSuccess: () => {
                    setOpen(false);
                },
            }));
        }
    };

    const close = () => {
        dispatch(closeTransaction());
    };

    return (
        <div className="flex-center col">
            {
                noAccess
                    ? <NoAccess close={close} />
                    : (
                        <div
                            style={{
                                width: "80%",
                            }}
                        >
                            <InvitationModal
                                handleClose={() => { setOpen(false); }}
                                onSubmit={submit}
                                open={open}
                            />
                            <EncompassHeader />
                            <InvitationTable
                                loading={loading}
                                openDialog={() => { setOpen(true); }}
                                openEdit={() => { }}
                                openDelete={() => { }}
                            />
                        </div>
                    )
            }
        </div>
    );
};

export default EncompassTemplate;
