/* eslint-disable default-param-last */
// eslint-disable-next-line import/no-cycle
import { Payload } from ".";
import { AppDispatch } from "../../configureStore";
import sandbox from "../../sandbox";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, patch, post, put } from "../http/request";
import success from "../http/success";
import { GET_ORIGIN_FAILURE } from "./encompass";

export interface CreateInstitution {
    name: string;
    defaultAdmin: string;
    tenants: string[];
    encompassIds: string[];
    connectors: string[];
    csdeLink: string;
    userGroupId?: string;
    adminGroupId?: string;
}

export interface UpdateEmail {
    institutionId: string;
    jsonFormat: string;
    subject: string;
    html: string;
}

export interface EditInstitution extends CreateInstitution {
    id: string;
}

export interface Me {
    email: string;
    name: string;
    level: 0 | 1 | 2;
    imageUrl?: string;
    role: 'Admin' | 'User';
    employeeId: string;
    institutionId: string;
    fiCode?: string;
}

export interface Institution {
    id: string;
    defaultAdmin: string;
    csdeLink: string;
    name: string;
    level: 0 | 1 | 2;
    tenants: string[];
    encompassInstances: string[];
    connectors: string[];
    enabled: boolean;
    userGroupId: string;
    adminGroupId: string;
    transcripts: {
        fiCode: string;
        active: boolean;
    };
    createdAt: Date | string;
    updatedAt: Date | string;
    email: {
        html: string;
        jsonFormat: string;
        subject: string;
    }
}

interface MeResponse extends Me {
    institution: any;
}

const initialState: {
    me?: Me;
    institution?: any;
    institutionId?: string;
    institutions: Institution[];
    noAccess: boolean;
    userNotFound: boolean;
} = {
    institutions: [],
    noAccess: false,
    userNotFound: false,
};

export const GET_ME_REQUEST = 'auth/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

export const GET_INSTITUTIONS_REQUEST = 'auth/GET_INSTITUTIONS_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'auth/GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'auth/GET_INSTITUTIONS_FAILURE';

export const CREATE_INSTITUTION_REQUEST = 'auth/CREATE_INSTITUTION_REQUEST';
export const CREATE_INSTITUTION_SUCCESS = 'auth/CREATE_INSTITUTION_SUCCESS';
export const CREATE_INSTITUTION_FAILURE = 'auth/CREATE_INSTITUTION_FAILURE';

export const UPDATE_INSTITUTION_REQUEST = 'auth/UPDATE_INSTITUTION_REQUEST';
export const UPDATE_INSTITUTION_SUCCESS = 'auth/UPDATE_INSTITUTION_SUCCESS';
export const UPDATE_INSTITUTION_FAILURE = 'auth/UPDATE_INSTITUTION_FAILURE';

export const UPDATE_INSTITUTION_EMAIL_REQUEST = 'auth/UPDATE_INSTITUTION_EMAIL_REQUEST';
export const UPDATE_INSTITUTION_EMAIL_SUCCESS = 'auth/UPDATE_INSTITUTION_EMAIL_SUCCESS';
export const UPDATE_INSTITUTION_EMAIL_FAILURE = 'auth/UPDATE_INSTITUTION_EMAIL_FAILURE';

export const SET_INSTITUTION_ID = 'auth/SET_INSTITUTION_ID';

type ActionParams =
    | { type: typeof GET_ME_SUCCESS; payload: Payload; data: MeResponse }
    | { type: typeof SET_INSTITUTION_ID; input: string }
    | { type: typeof GET_ME_FAILURE; data: string | { isSuccess: boolean; responseData?: string; } }
    | { type: typeof GET_INSTITUTIONS_SUCCESS; payload: Payload; data: Institution[]; }
    | { type: typeof CREATE_INSTITUTION_SUCCESS; data: Institution; }
    | { type: typeof UPDATE_INSTITUTION_SUCCESS; data: Institution; }
    | { type: typeof UPDATE_INSTITUTION_EMAIL_SUCCESS; data: Institution; }
    | { type: typeof GET_ORIGIN_FAILURE; data: string; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case UPDATE_INSTITUTION_SUCCESS: {
            const copied = JSON.parse(JSON.stringify(state.institutions)) as Institution[];

            const idx = copied.findIndex(({ id: institutionId }) => institutionId === action.data.id);
            if (idx === -1) return state;
            copied[idx] = action.data;
            return {
                ...state,
                institutions: copied,
            };
        }

        case UPDATE_INSTITUTION_EMAIL_SUCCESS: {
            const copied = JSON.parse(JSON.stringify(state.institutions)) as Institution[];

            const idx = copied.findIndex(({ id: institutionId }) => institutionId === action.data.id);
            if (idx === -1) return state;
            copied[idx] = action.data;
            return {
                ...state,
                institutions: copied,
            };
        }

        case CREATE_INSTITUTION_SUCCESS: {
            return {
                ...state,
                institutions: [
                    ...state.institutions,
                    action.data,
                ],
            };
        }

        case GET_ME_SUCCESS:
            if (action.data) {
                return {
                    ...state,
                    me: {
                        email: action.data.email,
                        employeeId: action.data.employeeId,
                        level: action.data.level,
                        name: action.data.name,
                        role: action.data.role,
                        institutionId: action.data.institutionId,
                        fiCode: action.data.institution?.fiCode,
                    },
                    institution: action.data.institution,
                };
            }
            return state;
        case GET_INSTITUTIONS_SUCCESS:
            return {
                ...state,
                institutions: action.data,
            };
        case SET_INSTITUTION_ID:
            sandbox.set('viewAs', action.input);
            return {
                ...state,
                institutionId: action.input,
            };
        case GET_ME_FAILURE:
            if (typeof action.data === 'string') {
                return {
                    ...state,
                    noAccess: action?.data?.includes('402'),
                };
            }
            return {
                ...state,
                noAccess: action.data?.responseData?.startsWith('unable') || true,
                userNotFound: action.data?.responseData?.startsWith('unable') || true,
            };
        case GET_ORIGIN_FAILURE:
            return {
                ...state,
                noAccess: action?.data?.includes('401'),
            };
        default:
            return state;
    }
}

export const getMe = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    execute(get, '/auth')
        .then(success(dispatch, GET_ME_SUCCESS, payload))
        .catch(failure(dispatch, GET_ME_FAILURE, payload));

    dispatch({
        type: GET_ME_REQUEST,
        payload,
    });
};

export const getInstitutions = (payload?: Payload<any>) => (dispatch: AppDispatch) => {
    execute(get, '/institutions')
        .then(success(dispatch, GET_INSTITUTIONS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSTITUTIONS_FAILURE, payload));

    dispatch({
        type: GET_INSTITUTIONS_REQUEST,
        payload,
    });
};

export const updateInstitutionEmail = (payload: Payload<UpdateEmail>) => (dispatch: AppDispatch) => {
    execute(put, '/institution/email', payload.input)
        .then(success(dispatch, UPDATE_INSTITUTION_EMAIL_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_INSTITUTION_EMAIL_FAILURE, payload));

    dispatch({
        type: UPDATE_INSTITUTION_EMAIL_REQUEST,
        payload,
    });
};

export const setInstitution = (id: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_INSTITUTION_ID,
        input: id,
    });
};

export const createInstitution = (payload: Payload<CreateInstitution>) => (dispatch: AppDispatch) => {
    execute(post, '/institution', payload.input)
        .then(success(dispatch, CREATE_INSTITUTION_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_INSTITUTION_FAILURE, payload));

    dispatch({
        type: CREATE_INSTITUTION_REQUEST,
        payload,
    });
};

export const editInstitution = (payload: Payload<EditInstitution>) => (dispatch: AppDispatch) => {
    execute(patch, '/institution', payload.input)
        .then(success(dispatch, UPDATE_INSTITUTION_SUCCESS, payload))
        .catch(failure(dispatch, UPDATE_INSTITUTION_FAILURE, payload));

    dispatch({
        type: UPDATE_INSTITUTION_REQUEST,
        payload,
    });
};

export default reducer;
