/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { Institution, createInstitution, editInstitution } from "../../redux/reducer/auth";
import Icon from "../atoms/Icon";
import globalStyles from "../../globalStyles";
import Button from "../atoms/Button";
import { ERROR_MESSAGE } from "../../redux/reducer/status";

interface InstitutionDialogParams {
    open: boolean;
    setOpen: (_: boolean) => void;
    active?: string;
}

const InstitutionDialog = ({
    open,
    setOpen,
    active,
}: InstitutionDialogParams) => {
    const institution = useSelector<RootState, Institution | undefined>(({ auth: { institutions } }) => institutions.find(({ id }) => id === active));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/CREATE_INSTITUTION']);

    const dispatch = useDispatch<AppDispatch>();

    const [name, setName] = useState<string>();
    const [csdeLink, setCsdeLink] = useState<string>();
    const [defaultAdmin, setDefaultAdmin] = useState<string>();
    const [tenants, setTenants] = useState<string[]>([]);
    const [encompassIds, setEncompassIds] = useState<string[]>([]);
    const [connectors, setConnectors] = useState<string[]>([]);
    const [userGroupId, setUserGroupId] = useState<string>();
    const [adminGroupId, setAdminGroupId] = useState<string>();

    const [errors, _setErrors] = useState<{
        name?: string;
        defaultAdmin?: string;
        fiCodes?: string;
        encompassIds?: string;
        userGroupId?: string;
        connectors?: string;
        adminGroupId?: string;
        csdeLink?: string;
    }>({});

    useEffect(() => {
        if (institution) {
            setName(institution.name);
            setDefaultAdmin(institution.defaultAdmin);
            setTenants(institution.tenants);
            setEncompassIds(institution.encompassInstances);
            setConnectors(institution.connectors);
            setUserGroupId(institution.userGroupId);
            setCsdeLink(institution.csdeLink);
            setAdminGroupId(institution.adminGroupId);
        }
    }, [institution]);

    const handleSubmit = () => {
        if (!name || !defaultAdmin || !csdeLink) {
            dispatch({
                type: ERROR_MESSAGE,
                message: `Missing institution name or default admin`,
            });
        } else if (!active) {
            dispatch(
                createInstitution({
                    input: {
                        name,
                        defaultAdmin,
                        tenants,
                        encompassIds,
                        csdeLink,
                        connectors,
                        userGroupId,
                        adminGroupId,
                    },
                    onSuccess: () => {
                        setOpen(false);
                        setName('');
                        setDefaultAdmin('');
                        setTenants([]);
                        setEncompassIds([]);
                        setUserGroupId('');
                        setCsdeLink('');
                        setAdminGroupId('');
                        setConnectors([]);
                    },
                }),
            );
        } else {
            dispatch(
                editInstitution({
                    input: {
                        id: active,
                        name,
                        defaultAdmin,
                        tenants,
                        encompassIds,
                        connectors,
                        csdeLink,
                        userGroupId,
                        adminGroupId,
                    },
                    onSuccess: () => {
                        setOpen(false);
                        setName('');
                        setDefaultAdmin('');
                        setTenants([]);
                        setEncompassIds([]);
                        setUserGroupId('');
                        setCsdeLink('');
                        setAdminGroupId('');
                        setConnectors([]);
                    },
                }),
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setOpen(false);
                setName('');
                setDefaultAdmin('');
                setTenants([]);
                setEncompassIds([]);
                setUserGroupId('');
                setCsdeLink('');
                setConnectors([]);
                setAdminGroupId('');
            }}
            fullWidth
        >
            <DialogTitle id="institution-dialog-title">{institution ? 'Edit' : 'Create'} Institution</DialogTitle>
            <DialogContent
                dividers
            >
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Institution Name"
                                name="name"
                                variant="outlined"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="CSDELinkInput"
                                label="CSDE Link"
                                name="csdeLink"
                                variant="outlined"
                                fullWidth
                                value={csdeLink}
                                onChange={(e) => setCsdeLink(e.target.value)}
                                required
                                error={Boolean(errors.csdeLink)}
                                helperText={errors.csdeLink}
                            />
                            <label htmlFor="CSDELinkInput" style={{ fontSize: 8 }}>Compensation and Suitability and a Disclaimer of Endorsement Link</label>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Admin Email"
                                name="defaultAdmin"
                                variant="outlined"
                                fullWidth
                                value={defaultAdmin}
                                onChange={(e) => setDefaultAdmin(e.target.value)}
                                required
                                error={Boolean(errors.defaultAdmin)}
                                helperText={errors.defaultAdmin}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ borderTopColor: 'grey', borderTopWidth: 1, borderTopStyle: 'solid', paddingTop: '2px !important', marginTop: 2 }}
                        >
                            <Typography>Required for Web App Clients</Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                label="User Group ID"
                                name="userGroupId"
                                variant="outlined"
                                fullWidth
                                value={userGroupId}
                                onChange={(e) => setUserGroupId(e.target.value)}
                                error={Boolean(errors.userGroupId)}
                                helperText={errors.userGroupId}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Admin Group ID"
                                name="adminGroupId"
                                variant="outlined"
                                fullWidth
                                value={adminGroupId}
                                onChange={(e) => setAdminGroupId(e.target.value)}
                                error={Boolean(errors.adminGroupId)}
                                helperText={errors.adminGroupId}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ borderTopColor: 'grey', borderTopWidth: 1, borderTopStyle: 'solid', paddingTop: '4px !important', marginTop: 2 }}
                        >
                            {tenants.map((tenantId, idx) => (
                                <Grid container sx={globalStyles.mb2}>
                                    <Grid item xs={11}>
                                        <TextField
                                            label="Tenant ID"
                                            name={`tenantId-${idx}`}
                                            variant="outlined"
                                            fullWidth
                                            value={tenantId}
                                            onChange={(e) => {
                                                const copied = JSON.parse(JSON.stringify(tenants));
                                                copied[idx] = e.target.value;
                                                setTenants(copied);
                                            }}
                                            required
                                            error={Boolean(errors.fiCodes)}
                                            helperText={errors.fiCodes}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={globalStyles.flexCenterCenter}
                                    >
                                        <Icon
                                            name="Close"
                                            onClick={() => {
                                                const copied: string[] = JSON.parse(JSON.stringify(tenants));
                                                copied.splice(idx, 1);
                                                setTenants(copied);
                                            }}
                                            color="error"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            sx={globalStyles.flexCenterEnd}
                            item
                            xs={12}
                        >
                            <Button
                                sx={globalStyles.flexCenterStart}
                                variant="contained"
                                onClick={() => {
                                    const copied: string[] = JSON.parse(JSON.stringify(tenants));
                                    copied.push('');
                                    setTenants(copied);
                                }}
                            >
                                <Typography>Add Tenant ID</Typography>
                                <Icon name="Add" />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {encompassIds.map((encId, idx) => (
                                <Grid container sx={globalStyles.mb2}>
                                    <Grid item xs={11}>
                                        <TextField
                                            label="Encompass Instance ID"
                                            name={`encompassId-${idx}`}
                                            variant="outlined"
                                            fullWidth
                                            value={encId}
                                            onChange={(e) => {
                                                const copied = JSON.parse(JSON.stringify(encompassIds));
                                                copied[idx] = e.target.value;
                                                setEncompassIds(copied);
                                            }}
                                            required
                                            error={Boolean(errors.encompassIds)}
                                            helperText={errors.encompassIds}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={globalStyles.flexCenterCenter}
                                    >
                                        <Icon
                                            name="Close"
                                            onClick={() => {
                                                const copied: string[] = JSON.parse(JSON.stringify(encompassIds));
                                                copied.splice(idx, 1);
                                                setEncompassIds(copied);
                                            }}
                                            color="error"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            sx={globalStyles.flexCenterEnd}
                            item
                            xs={12}
                        >
                            <Button
                                sx={globalStyles.flexCenterStart}
                                variant="contained"
                                onClick={() => {
                                    const copied: string[] = JSON.parse(JSON.stringify(encompassIds));
                                    copied.push('');
                                    setEncompassIds(copied);
                                }}
                            >
                                <Typography>Add Encompass ID</Typography>
                                <Icon name="Add" />
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {connectors.map((conId, idx) => (
                                <Grid container sx={globalStyles.mb2}>
                                    <Grid item xs={11}>
                                        <TextField
                                            label="API Connector Client ID"
                                            name={`connector-${idx}`}
                                            variant="outlined"
                                            fullWidth
                                            value={conId}
                                            onChange={(e) => {
                                                const copied = JSON.parse(JSON.stringify(connectors));
                                                copied[idx] = e.target.value;
                                                setConnectors(copied);
                                            }}
                                            required
                                            error={Boolean(errors.connectors)}
                                            helperText={errors.connectors}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={globalStyles.flexCenterCenter}
                                    >
                                        <Icon
                                            name="Close"
                                            onClick={() => {
                                                const copied: string[] = JSON.parse(JSON.stringify(connectors));
                                                copied.splice(idx, 1);
                                                setConnectors(copied);
                                            }}
                                            color="error"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            sx={globalStyles.flexCenterEnd}
                            item
                            xs={12}
                        >
                            <Button
                                sx={globalStyles.flexCenterStart}
                                variant="contained"
                                onClick={() => {
                                    const copied: string[] = JSON.parse(JSON.stringify(connectors));
                                    copied.push('');
                                    setConnectors(copied);
                                }}
                            >
                                <Typography>Add API Connector</Typography>
                                <Icon name="Add" />
                            </Button>
                        </Grid>

                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Create
                        </Button>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default InstitutionDialog;
