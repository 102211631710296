import { Avatar, Box, Typography } from '@mui/material';
import ColorHash from 'color-hash';
import globalStyles from '../../globalStyles';

interface UserWithIconParams {
    firstName: string;
    lastName: string;
    imageUrl?: string;
    maxWidth?: string | number;
}

const UserWithIcon = ({
    firstName,
    lastName,
    imageUrl,
    maxWidth,
}: UserWithIconParams) => {
    const name = `${firstName} ${lastName}`;
    const inits = `${firstName.charAt(0) ?? ""}${lastName.charAt(0) ?? ""}`;
    const colorHash = new ColorHash({
        lightness: 0.5,
        hue: { min: 90, max: 270 },
    });

    return (
        <Box
            sx={[
                globalStyles.flexCenter,
            ]}
        >
            {
                imageUrl ? (
                    <Avatar
                        alt={name}
                        src={imageUrl}
                        sx={globalStyles.mr1}
                    />
                ) : (
                    <Avatar
                        alt={name}
                        sx={[
                            {
                                bgcolor: colorHash.hex(inits),
                                fontSize: 14,
                                textTransform: 'uppercase',
                                fontWeight: '600',
                            },
                            globalStyles.mr1,
                        ]}
                    >
                        {inits}
                    </Avatar>
                )
            }
            <Typography
                className="my-0"
                fontSize={14}
                textAlign="left"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxWidth={maxWidth}
            >
                {lastName}, {firstName}
            </Typography>
        </Box>
    );
};

export default UserWithIcon;
