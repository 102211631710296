import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Typography } from "@mui/material";

import Table from "./Table";
import { AppDispatch, RootState } from "../../configureStore";
import {
    Borrower,
    BorrowerStatus,
    Filters,
    setFiltersEmail,
    setFiltersName,
    setFiltersInvited,
    setFiltersStatus,
} from "../../redux/reducer/borrowers";
import { IColumnType } from "../../utils/interface";
import StatusChip from "../atoms/StatusChip";
import UserWithIcon from "../molecules/UserWithIcon";
import sandbox from "../../sandbox";
import ActionColumn from "../molecules/ActionColumn";

interface InvitationTableParams {
    loading: boolean;
    openDialog: () => void;
    openEdit: () => void;
    openDelete: () => void;
}

const InvitationTable = ({
    loading,
    openDialog,
    openEdit,
    openDelete,
}: InvitationTableParams) => {
    const disabled = useSelector<RootState, boolean>(({ auth }) => (!!auth?.institutionId && auth?.institutionId !== auth?.me?.institutionId));
    const borrowers = useSelector<RootState, Borrower[]>(({ borrowers: brwrs }) => brwrs.borrowers);
    const role = useSelector<RootState, string>(({ auth: { me } }) => me?.role || '');
    const filters = useSelector<RootState, Filters>(({ borrowers: brwrs }) => brwrs.filters);

    const isEncompass = useMemo(() => sandbox.get('encompass'), [sandbox]);

    const dispatch = useDispatch<AppDispatch>();

    const columns = useMemo<IColumnType<Borrower>[]>(() => {
        const defaultColumns: IColumnType<Borrower>[] = [
            {
                title: 'Client',
                key: 'name',
                filterItems: [...new Set(borrowers.map(({ name }) => name))].filter((n) => Boolean(n)),
                render: (_: string, item: Borrower) => (
                    <UserWithIcon
                        firstName={item.firstName || ''}
                        lastName={item.lastName || ''}
                        imageUrl={item.imageUrl}
                    />
                ),
                onFilter: (list: string[]) => { dispatch(setFiltersName(list)); },
            },
            {
                title: 'Email',
                key: 'email',
                filterItems: [...new Set(borrowers.map(({ email }) => email))].filter((v) => Boolean(v)),
                onFilter: (list: string[]) => { dispatch(setFiltersEmail(list)); },
                render: (val) => (<Typography fontSize={14} textAlign="left">{val}</Typography>),
            },
            {
                title: 'Status',
                key: 'status',
                render: (_: string, item: Borrower) => <StatusChip status={item.status} />,
                filterItems: [BorrowerStatus.ACCEPTED, BorrowerStatus.NOT_ACCEPTED, BorrowerStatus.NOT_INVITED, BorrowerStatus.UNSUBSCRIBED],
                // eslint-disable-next-line react/no-unstable-nested-components
                filterRender: (display: string) => <StatusChip status={display as BorrowerStatus} />,
                onFilter: (list: string[]) => { dispatch(setFiltersStatus(list as BorrowerStatus[])); },
            },
            {
                title: 'Invited On',
                key: 'invitedOn',
                render: (val: string) => (val ? moment(val).format('MM/DD/YYYY') : ''),
            },
            {
                title: 'Accepted On',
                key: 'acceptedOn',
                render: (val: string) => (val ? moment(val).format('MM/DD/YYYY') : ''),
            },
            {
                title: 'Invitation Code',
                key: 'invitationCode',
            },
            {
                title: 'Action',
                key: 'id',
                render: (_id: string, item: Borrower) => (
                    <ActionColumn
                        borrower={item}
                        isEncompass={isEncompass}
                        inviteDisabled={disabled}
                        openDialog={openDialog}
                        openEdit={openEdit}
                        openDelete={openDelete}
                    />
                ),
            },
        ];

        const createdOnCol: IColumnType<Borrower> = {
            title: 'Created On',
            key: 'createdOn',
            render: (val: string) => (val ? moment(val).format('MM/DD/YYYY') : ''),
        };
        if (!isEncompass) {
            const indx = defaultColumns.findIndex(({ title }) => title === 'Email');
            defaultColumns.splice(indx, 0, createdOnCol);
        }

        const invitedByColumn: IColumnType<Borrower> = {
            title: 'Invited By',
            key: 'invitedBy',
            filterItems: [...new Set(borrowers.map(({ invitedBy, invitedByName }) => invitedByName || invitedBy || ''))].filter((v) => Boolean(v)),
            render: (_val: string, item: Borrower) => (item.invitedByName ? (
                <UserWithIcon
                    firstName={item.invitedByName.split(' ')[0] || ''}
                    lastName={item.invitedByName.substring(item.invitedByName.indexOf(' ') + 1) || ''}
                    imageUrl={item.imageUrl}
                    maxWidth={180}
                />
            ) : item.invitedBy || ''),
            onFilter: (list: string[]) => { dispatch(setFiltersInvited(list)); },
        };

        if (role !== 'Loan Professional' && !isEncompass) defaultColumns.splice(6, 0, invitedByColumn);
        return defaultColumns;
    }, [
        borrowers,
        role,
        disabled,
        isEncompass,
    ]);

    const data = useMemo(
        () => borrowers.filter((bwr: any) => Object
            .keys(filters)
            .every((key: string) => (
                filters[key as keyof Filters].length === 0
                || filters[key as keyof Filters].includes(bwr[key])
            ))),
        [borrowers, filters],
    );

    return (
        <Table
            columns={columns}
            data={data}
            keyPrefix="borrower"
            loading={loading}
            emptyComponentText="No Clients Found"
        />
    );
};

export default InvitationTable;
