import { Box } from "@mui/material";
import { useDispatch } from "react-redux";

import Button from "../atoms/Button";
import globalStyles from "../../globalStyles";
import { Borrower, BorrowerStatus, SET_ACTIVE_BORROWER } from "../../redux/reducer/borrowers";
import { AppDispatch } from "../../configureStore";

interface InviteActionButtonParams {
    item: Borrower;
    disabled: boolean;
    openDialog: () => void;
}

const InviteActionButton = ({
    item,
    disabled,
    openDialog,
}: InviteActionButtonParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <Box sx={globalStyles.flexCenterCenter}>
            {item.status === BorrowerStatus.NOT_INVITED ? (
                <Button
                    sx={[{ width: 132 }, globalStyles.flexCenterCenter]}
                    variant="contained"
                    disabled={disabled}
                    onClick={() => {
                        dispatch({
                            type: SET_ACTIVE_BORROWER,
                            data: item,
                        });
                        openDialog();
                    }}
                >
                    Invite
                </Button>
            ) : item.status === BorrowerStatus.NOT_ACCEPTED ? (
                <Button
                    sx={[{ width: 132 }, globalStyles.flexCenterCenter]}
                    variant="contained"
                    disabled={disabled}
                    onClick={() => {
                        dispatch({
                            type: SET_ACTIVE_BORROWER,
                            data: item,
                        });
                        openDialog();
                    }}
                >
                    <div style={{ whiteSpace: 'nowrap' }}>
                        Resend Invite
                    </div>
                </Button>
            ) : <Box sx={[{ width: 132 }, globalStyles.flexCenterCenter]} />}
        </Box>
    );
};
export default InviteActionButton;
