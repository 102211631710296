import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    useTheme,
} from "@mui/material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer } from "../templates/Header";
import { IRoute } from "../../interfaces";
import Icon from "../atoms/Icon";

interface SiderParams {
    open: boolean;
    setOpen: (_: boolean) => void;
    routes: IRoute[];
}

const NavItem = ({
    active,
    route,
    open,
    navigate,
    theme,
}: {
    open: boolean;
    active: boolean;
    route: IRoute;
    navigate: NavigateFunction,
    theme: Theme;
}) => (
    <ListItem key={route.name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
            sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
            }}
            onClick={() => { navigate(route.pathname); }}
        >
            <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                <Icon name={(active && route.activeIcon) ? route.activeIcon : route.icon} color={active ? "primary" : undefined} />
            </ListItemIcon>
            <ListItemText
                primary={route.name}
                sx={{
                    opacity: open ? 1 : 0,
                    color: active ? theme.palette.primary.dark : undefined,
                }}
            />
        </ListItemButton>
    </ListItem>
);

const Sider = ({
    setOpen,
    open,
    routes,
}: SiderParams) => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Drawer variant="permanent" open={open}>
            <List sx={{ paddingTop: "64px" }}>
                <ListItem
                    key="open"
                    disablePadding
                >
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: 'center',
                            px: 2.5,
                        }}
                        onClick={() => { setOpen(!open); }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                justifyContent: 'center',
                            }}
                        >
                            {!open ? <MenuIcon /> : <MenuOpenIcon />}
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                {routes.map((route) => (
                    <NavItem
                        key={`nav-${route.name}`}
                        route={route}
                        active={pathname === route.pathname}
                        navigate={navigate}
                        theme={theme}
                        open={open}
                    />
                ))}
            </List>
        </Drawer>
    );
};
export default Sider;
