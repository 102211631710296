import { Checkbox, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import Icon from "../atoms/Icon";

interface TableFilterParams {
    id: string;
    items: string[];
    filterRender?: (display: string) => React.ReactElement;
    onFilter: (list: string[]) => void;
}

const TableFilter = ({
    id,
    items = [],
    filterRender,
    onFilter,
}: TableFilterParams) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [activeList, setActiveList] = useState<string[]>([]);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Icon
                id={`${id}-button`}
                name="Tune"
                onClick={handleClick}
            />
            <Menu
                id={`${id}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    items.map((value, idx) => (
                        <MenuItem
                            onClick={() => {
                                const newList = activeList.includes(value) ? activeList.filter((val) => val !== value) : [...activeList, value];
                                setActiveList(newList);
                                onFilter(newList);
                            }}
                            key={`filter-${value}-${idx}`}
                        >
                            <Checkbox checked={activeList.includes(value)} />
                            {filterRender ? filterRender(value) : value}
                        </MenuItem>
                    ))
                }
            </Menu>
        </>

    );
};

export default TableFilter;
