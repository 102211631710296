import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import Sider from '../organisms/Sider';
import { IRoute } from '../../interfaces';
import AppHeader from '../organisms/AppHeader';
import globalStyles from '../../globalStyles';
import NoAccess from '../organisms/NoAccess';
import { RootState } from "../../configureStore";
import { Me } from "../../redux/reducer/auth";
import LoadingOverlay from "../atoms/LoadingOverlay";

export default function SaaSTemplate({ noAccess }: { noAccess: boolean; }) {
    const { instance } = useMsal();
    const { pathname } = useLocation();
    const me = useSelector<RootState, Me | undefined>(({ auth }) => auth.me);
    const meLoading = useSelector<RootState, boolean>(({ status }) => status.loading['auth/GET_ME']);
    const [open, setOpen] = useState(false);

    const routes = useMemo<IRoute[]>(() => {
        const rows = [
            {
                icon: 'DashboardOutlined',
                activeIcon: 'Dashboard',
                name: 'Dashboard',
                pathname: '/',
            },
        ];

        if (me?.level === 0) {
            rows.push({
                icon: 'AccountBalanceOutlined',
                activeIcon: 'AccountBalance',
                name: 'Institutions',
                pathname: '/institutions',
            });
        }
        return rows;
    }, [me]);

    const active = useMemo<IRoute | undefined>(() => routes.find(({ pathname: pnm }) => pnm === pathname), [routes, pathname]);

    return noAccess ? <NoAccess instance={instance} /> : (
        <Box
            sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                color: "#000133",
            }}
        >
            <AppHeader
                active={active}
                open={open}
            />
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                }}
            >
                <Sider
                    setOpen={setOpen}
                    open={open}
                    routes={routes}
                />
                <Box
                    component="main"
                    sx={[
                        {
                            flex: 1,
                            background: "#f7f5f5",
                            color: "black",
                        },
                        globalStyles.pt8,
                        globalStyles.px4,
                    ]}
                >
                    {meLoading ? <LoadingOverlay /> : <Outlet />}
                </Box>
            </Box>
        </Box>
    );
}
