/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import auth from "./auth";
import borrowers from "./borrowers";
import encompass from "./encompass";
import status from "./status";

export interface Payload<T = any> {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
    headers?: T;
    input?: T;
    data?: T;
    noLoad?: boolean;
}

const rootReducer = combineReducers({
    auth,
    borrowers,
    encompass,
    status,
});

export default rootReducer;
