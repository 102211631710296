import host, { TransactionRequest } from '@elliemae/em-ssf-guest';

export interface IHostConnection {
    getTransactionOrigin: () => Promise<{ id: string; partnerAccessToken: string; }>;
    createTransaction: (request: TransactionRequest) => Promise<string>;
    refreshOrigin: () => Promise<{ id: string; partnerAccessToken: string; }>;
    close: () => Promise<void>;
    getApplicationCapabilities: any;
    openResource: any;
    openResourceInModal: any;
}

const HostConnection = async () => {
    // Guest application initialization callback
    host.connect();
    host.ready();

    // Instantiate the transaction and application JavaScript objects for use
    const TransactionObject = await host.getObject('transaction');
    const ApplicationObject = await host.getObject('application');

    const getTransactionOrigin = async () => TransactionObject.getOrigin();
    const refreshOrigin = async () => TransactionObject.refreshOrigin();
    const createTransaction = async (request: TransactionRequest) => TransactionObject.create(request);
    const close = async () => TransactionObject.close();
    const getApplicationCapabilities = async () => ApplicationObject.getCapabilities();
    const openResource = async (resourceReference: any) => {
        await ApplicationObject.open(resourceReference);
    };
    const openResourceInModal = async (resourceReference: any) => {
        await ApplicationObject.openModal(resourceReference);
    };

    // Return the public interface for this service
    return {
        // Transaction Object interface
        getTransactionOrigin,
        createTransaction,
        refreshOrigin,
        close,

        // Application Object interface
        getApplicationCapabilities,
        openResource,
        openResourceInModal,
    };
};

export default HostConnection;
