import moment from 'moment';

export interface IRIA {
    version: string;
    text: string[];
}

const RIA_SERVICE_NAME = 'Go Plan 101';

const text = [
    'The Referring Party hereby declares and confirms the following:',
    "Independent Services: The referral of clients from the Referring Party to the Referred Party has no bearing whatsoever on the decisioning with regard to the loan services provided by the Referring Party. The decision to use or not to use the services of the Referred Party does not influence any aspect of the client's loan process or application.",
    "No Financial Advice: The Referring Party has not offered or provided any financial advice to the client in relation to the Referred Party's services or any other investment services or products. The Referring Party has not recommended any specific financial products, services or strategies to the client.",
    'No Market or Economic Discussions: The Referring Party has not engaged in any discussions with the client concerning the market, economic outlooks, or potential returns of any investment opportunities or strategies.',
    'No Contingency: Any loan product or service offered by the Referring Party is not contingent upon the client doing any business with the Referred Party, participating in any investment, or purchasing any financial product or service.',
    'Compliance with RESPA: The Referring Party affirms its familiarity with the Real Estate Settlement Procedures Act (RESPA) and internal firm policies and confirms that it has not violated any RESPA rules or regulations in the referral process or in any other interaction with the client.',
    'Compliance with FINRA and SEC: The Referring Party acknowledges that it is a violation of the Financial Industry Regulatory Authority (FINRA) and the Securities and Exchange Commission (SEC) rules to provide financial advice as an unlicensed individual. The Referring Party confirms that it has not violated those rules and has not provided any form of investment advice to any client involved in this transaction.',
    'Compensation Arrangement for Referral: [MORTGAGE COMPANY NAME] may receive compensation in the form of a referral fee from [RIA SERVICE NAME] for referring clients to their services. This referral fee arrangement has no bearing on the decisioning regarding the loan services provided by [MORTGAGE COMPANY NAME]. Decisions on loan products or services are made independently of whether the client chooses to do business with [RIA SERVICE NAME].',
    'Disclosure of Compensation: The compensation arrangement for referrals is fully disclosed to our clients. The compensation received by [MORTGAGE COMPANY NAME] is solely for the referral of the client and is not based on any action or advice provided by [RIA SERVICE NAME].',
    "Client Best Interest: We only refer clients to [RIA SERVICE NAME] if we believe it is in their best interest. The decision to use or not to use the services of [RIA SERVICE NAME] does not influence any aspect of the client's loan process or application with [MORTGAGE COMPANY NAME].",
    'Regulatory Compliance: The Referring Party affirms its familiarity with SEC, FINRA, and RESPA rules and confirms that it has not violated any of these rules in the referral process or in any other interaction with the client.',
    "Client Information Protection: This referral arrangement does not involve sharing the client's personal information, including Personally Identifiable Information (PII), without the explicit consent of the borrower. The privacy and protection of the borrower's information are upheld, and the Referring Party ensures that any disclosures or sharing of information comply with applicable laws and regulations.",
    'Required Disclosures: [RIA SERVICE NAME] is obligated to provide a disclosure document, such as a Form ADV, outlining their background, experience, and compensation structure during the initial meeting with the client. This disclosure helps ensure transparency about the referred services and potential costs.',
    "Third-Party Involvement: The use of a non-licensed third-party firm as a middleman to facilitate the transaction may occur. This allows [MORTGAGE COMPANY NAME] to offer additional financial services without requiring an investment advisory license, broadening [RIA SERVICE NAME]'s client base through referrals. However, the client should be aware that this could introduce additional fees, which would be disclosed upfront.",
    'By accepting the referral, the client acknowledges their understanding of the above-mentioned points and that they have the discretion to accept or decline the services of the Referred Party. If there are any queries or concerns about this referral process, clients are encouraged to contact Halcyon at info@halcyonsw.com',
    '[MORTGAGE COMPANY NAME]',
    '[DATE]',
];

const textV2 = [
    `The Loan Officer signing below and hereby adding a client ("Client”) to the [MORTGAGE COMPANY NAME] RIA Program (the "Program”) client list "(the "Client List”) to be provided to Wealth Enhancement Group, Inc., a Registered Investment Advisor ("WEG”) through a third-party administrator used to administrate the Program ("TPA”) hereby declares and confirms the following:`,
    `Independent Services: The addition of the Client to the Client List has no bearing whatsoever on the decisioning with regard to the loan services provided by the Loan Officer or [MORTGAGE COMPANY NAME], Inc. ("[MORTGAGE COMPANY NAME]”). The decision of the Client to use or not to use the services of WEG does not influence any aspect of the Client's loan process or application.`,
    `No Financial Advice or Endorsement: The Loan Officer has not offered or provided any financial advice or any endorsement of WEG to the Client in relation to WEG's services or any other investment services or products. The Loan Officer has not recommended or endorsed any specific financial advisor, financial products, services or strategies to the Client.`,
    `No Market or Economic Discussions: The Loan Officer has not engaged in any discussions with the Client concerning the market, economic outlooks, or potential returns of any investment opportunities or strategies.`,
    `No Contingency: Any loan product or service offered by the Loan Officer or [MORTGAGE COMPANY NAME] is not contingent or in any way conditioned upon the Client engaging with, participating in any investment, or purchasing any financial product or service from WEG.`,
    `Compliance with RESPA: The Loan Officer affirms their familiarity with the Real Estate Settlement Procedures Act (RESPA) and internal [MORTGAGE COMPANY NAME] policies and confirms that they have not violated any RESPA rules or regulations or [MORTGAGE COMPANY NAME] policies in adding Client to Client List or in any other interaction with the Client.`,
    `Compliance with FINRA, SEC and Blue-Sky Laws: The Loan Officer acknowledges and understands that it is a violation of (i) Financial Industry Regulatory Authority (FINRA) and (ii) Securities and Exchange Commission (SEC) rules and regulations as well as (iii) individual state Blue Sky laws to provide financial advice as an unlicensed individual. The Loan Officer confirms that they have not violated any of the aforementioned rules, regulations or laws and has not provided any form of investment advice or referral to any Client involved in this transaction.`,
    `Compensation Arrangement: The Loan Officer acknowledges that they have advised the Client that [MORTGAGE COMPANY NAME] will receive compensation from the Third Party for adding their name to the Client List and may receive additional compensation if the Client becomes a client of WEG and that any such fee arrangement has no bearing on the decisioning regarding the loan process or Client’s application by [MORTGAGE COMPANY NAME] and further, any decisions on loan products or applications are made independently of whether the Client becomes a client of RIA.`,
    `Regulatory Compliance: The Loan Officer affirms their familiarity with SEC, FINRA, state Blue Sky laws, RESPA and Truth-in Lending rules and regulations and confirms that they have not violated any of these laws, rules or regulations in adding Client to the Client List or in any other interaction with the Client.`,
    `Client Information Protection: The arrangement of adding Client to Client List does not involve sharing the Client's personal information, including Personally Identifiable Information (PII), with the exception of providing Client’s name and email address to TPA. The Loan Officer confirms that the privacy and protection of the Client's information have been upheld, and the Loan Officer confirms that any disclosures or sharing of any Client information comply with applicable laws and regulations.`,
    `Third-Party Involvement: The use of a non-licensed TPA as a conduit to facilitate the transmission of Client List will occur. This allows [MORTGAGE COMPANY NAME] to offer access to additional financial services to Client without requiring additional licensing. Loan Officer reaffirms that Client is aware that this could introduce additional fees outside and independent of the loan process, which has been disclosed in advance.`,
    `Disclosure of Compensation, Suitability and Disclaimer of Endorsement: The Loan Officer expressly acknowledges and agrees that (i) the compensation arrangement for providing the Client List to the TPA has been fully disclosed to the Client in advance and any compensation received by [MORTGAGE COMPANY NAME] is for providing the Client List to the TPA and in the event Client becomes a client of RIA, and in no event is based on any action or advice provided by WEG, (ii) the suitability and risks associated with the selection of any specific investment advisor was disclosed in advance to the Client and (iii) it was disclosed in advance to the Client that neither the Loan Officer nor [MORTGAGE COMPANY NAME] have made any endorsement or recommendation of a specific investment advisor, or is making the same, by virtue of offering the Program.   `,
    `[MORTGAGE COMPANY NAME]`,
    `[DATE]`,
];

const useV2 = true;

const riaText = (company: string): IRIA => ({
    version: useV2 ? '1.1.0' : '1.0.0',
    text: (useV2 ? textV2 : text).map((line) => line.replace(/\[MORTGAGE COMPANY NAME\]/g, company).replace(/\[RIA SERVICE NAME\]/g, RIA_SERVICE_NAME).replace(/\[DATE\]/g, moment().format('MMMM DD, YYYY'))),
});

export default riaText;
