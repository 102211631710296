import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import RIAForm from "../molecules/RIAForm";

interface CreateCustomerModalParams {
    open: boolean;
    handleClose: () => void;
    onSubmit: (
        _: {
            firstName: string;
            lastName: string;
            email: string;
        },
        _r?: boolean,
        _ce?: boolean,
        onDone?: () => void,
    ) => void;
}

const CreateCustomerModal = ({
    open,
    handleClose,
    onSubmit,
}: CreateCustomerModalParams) => (
    <Dialog
        open={open}
        onClose={() => {
            handleClose();
        }}
        aria-labelledby="create-modal-modal-title"
        aria-describedby="create-modal-modal-description"
    >
        <DialogTitle id="modal-scroll-dialog-title">Add Client</DialogTitle>
        <DialogContent dividers>
            <RIAForm onSubmit={onSubmit} isCreateOnly onClose={() => { handleClose(); }} />
        </DialogContent>
    </Dialog>
);

export default CreateCustomerModal;
