import { Alert, Snackbar } from "@mui/material";
import {
    InteractionType,
    PublicClientApplication,
} from "@azure/msal-browser";
import {
    AuthenticatedTemplate,
    MsalProvider,
    UnauthenticatedTemplate,
    useMsalAuthentication,
} from "@azure/msal-react";
import { Buffer } from 'buffer';

import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import store, { AppDispatch, RootState } from "../configureStore";
import { Me, getInstitutions, getMe, setInstitution } from "../redux/reducer/auth";
import SaaSTemplate from "../components/templates/SaaSTemplate";
import { msalConfig } from "../authConfig";
import Dashboard from "../components/templates/Dashboard";
import { jwtInterceptor } from "../jwtInterceptor";
import { clearError, clearSuccessful } from "../redux/reducer/status";
import TermsAndConditions from "../components/templates/TermsAndConditions";
import { noAuth } from "../utils";
import Institutions from "../components/templates/Institutions";
import EmailEdit from "../components/templates/EmailEdit";

interface SaaSAppParams {
}

const Pages = () => {
    const dispatch = useDispatch<AppDispatch>();
    const me = useSelector<RootState, Me>(({ auth }) => auth.me as Me);
    const noAccess = useSelector<RootState, boolean>(({ auth }) => auth.noAccess);
    const meFailed = useSelector<RootState, boolean>(({ status }) => status.failure['auth/GET_ME'] && !status.success['auth/GET_ME']);

    useEffect(() => {
        const instId = localStorage.getItem('InstitutionId');
        instId && dispatch(setInstitution(instId));
    }, []);

    useEffect(() => {
        if (!me && !meFailed) {
            dispatch(getMe({
                onSuccess: ({ data }) => {
                    if (data.level === 0) { dispatch(getInstitutions()); }
                },
            }));
        }
    }, [dispatch, meFailed]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/" element={<SaaSTemplate noAccess={noAccess} />}>
                    <Route index element={<Dashboard />} />
                    <Route path="institutions">
                        <Route index element={<Institutions />} />
                        <Route path=":institutionId/email" element={<EmailEdit />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const NoAuthPages = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<div />} />
            <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
    </BrowserRouter>
);

const SaaSApp = ({
}: SaaSAppParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`],
    };
    const { result } = noAuth.includes(window.location.pathname) ? { result: null } : useMsalAuthentication(InteractionType.Redirect, request);
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (error || successful) setOpen(true);
    }, [error, successful]);

    useEffect(() => {
        if (result) {
            const { accessToken, idToken } = result;
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('ID_TOKEN', idToken);
            try {
                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
            } catch (err) {
                console.error(err);
            }
        }
    }, [dispatch, result]);

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={((_e) => { dispatch(clearError()); dispatch(clearSuccessful()); setOpen(false); })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity={successful ? "success" : "error"} color={successful ? "success" : "error"}>
                    {successful || error}
                </Alert>
            </Snackbar>
            <AuthenticatedTemplate>
                <Pages />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NoAuthPages />
            </UnauthenticatedTemplate>
        </>
    );
};

interface SaaSParams {
}

const SaaS = ({
}: SaaSParams) => {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize();
    jwtInterceptor(msalInstance);

    return (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <SaaSApp />
            </Provider>
        </MsalProvider>
    );
};

export default SaaS;
