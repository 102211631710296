import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { getHost } from "../redux/reducer/encompass";
import store, { RootState } from "../configureStore";
import EncompassTemplate from "../components/templates/EncompassTemplate";
import { clearError, clearSuccessful } from "../redux/reducer/status";
import LoadingOverlay from "../components/atoms/LoadingOverlay";

interface EncompassParams {
}

const EncompassApp = ({
}: EncompassParams) => {
    const dispatch = useDispatch<any>();

    const hostSuccess = useSelector<RootState, boolean>(({ encompass }) => Boolean(encompass.host));
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        dispatch(getHost());
    }, [dispatch]);

    useEffect(() => {
        if (error || successful) setOpen(true);
    }, [error]);

    return (
        <div className="App">
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={((_e) => { dispatch(clearError()); dispatch(clearSuccessful()); setOpen(false); })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity={successful ? "success" : "error"} color={successful ? "success" : "error"}>
                    {successful || error}
                </Alert>
            </Snackbar>
            {
                hostSuccess ? (
                    <EncompassTemplate />
                ) : (
                    <LoadingOverlay />
                )
            }
        </div>
    );
};

const Encompass = () => (
    <Provider store={store}>
        <EncompassApp />
    </Provider>
);

export default Encompass;
