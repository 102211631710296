import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { Borrower, deleteCustomer } from "../../redux/reducer/borrowers";
import Button from "../atoms/Button";
import globalStyles from "../../globalStyles";

interface DeleteDialogParams {
    open: boolean;
    handleClose: () => void;
}

const DeleteDialog = ({
    open,
    handleClose,
}: DeleteDialogParams) => {
    const disabled = useSelector<RootState, boolean>(({ auth }) => (!!auth?.institutionId && auth?.institutionId !== auth?.me?.institutionId));
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['borrowers/DELETE_CUSTOMER']);
    const borrower = useSelector<RootState, Borrower | undefined>(({ borrowers }) => borrowers.activeBorrower);

    const dispatch = useDispatch<AppDispatch>();

    const deleteCust = () => { dispatch(deleteCustomer({ input: { id: borrower?.id ?? '' }, onSuccess: () => { handleClose(); } })); };

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
            }}
            aria-labelledby="delete-modal-title"
            aria-describedby="delete-modal-description"
        >
            <DialogTitle id="delete-dialog-title">Delete Customer</DialogTitle>
            <DialogContent>
                Are you sure you want to delete the account for Customer {borrower?.name}?
            </DialogContent>
            <DialogActions sx={globalStyles.flexCenterEnd}>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >
                    No, Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    loading={loading}
                    onClick={deleteCust}
                    disabled={disabled}
                >
                    Yes, Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
