import axios from "axios";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Button from "../atoms/Button";
import riaText, { IRIA } from "../../utils/riaText";
import globalStyles from "../../globalStyles";
import { AppDispatch } from "../../configureStore";
import { ERROR_MESSAGE } from "../../redux/reducer/status";

interface TermsAndConditionsParams {
}

const endpoint = process.env.REACT_APP_ENDPOINT || 'http://localhost:7071';

const TermsAndConditions = ({
}: TermsAndConditionsParams) => {
    const [loading, setLoading] = useState<boolean>(false);

    const companyName = useMemo(() => {
        const url = window.location.search.substring(1); // get rid of "?" in querystring
        const qArray = url.split('&'); // get key-value pairs
        for (let i = 0; i < qArray.length; i++) {
            const pArr = qArray[i].split('='); // split key and value
            if (pArr[0] === "name") {
                return decodeURI(pArr[1]);
            }
        }
        return "";
    }, [window.location.search]);

    const ria = useMemo<IRIA>(() => riaText(companyName), [companyName]);

    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = () => {
        setLoading(true);
        const instance = axios.create();
        instance.post(
            `${endpoint}/ria/agree`,
            {
                text: ria.text.join(' '),
                version: ria.version,
                companyName,
            },
            {
                headers: {
                    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_APIM_SUB_KEY,
                    Authorization: `Bearer ${null}`,
                },
            },
        ).then((res) => {
            if (window?.parent && document?.referrer) window.parent.postMessage({ key: res.data }, document.referrer);
            else if (window?.parent) window.parent.postMessage({ key: res.data }, "*");
        }).catch((err) => {
            if (err.response.status === 429) dispatch({ type: ERROR_MESSAGE, message: 'Too many requests!' });
            else dispatch({ type: ERROR_MESSAGE, message: 'Something went wrong!' });
        }).finally(() => {
            setLoading(false);
        });
    };

    return companyName ? (
        <Box sx={globalStyles.pa1}>
            {ria.text.map((text, idx) => <p key={idx}>{text}</p>)}
            <Box sx={globalStyles.flexCenterStart}>
                <Button
                    onClick={onSubmit}
                    loading={loading}
                    color="primary"
                    variant="contained"
                    sx={globalStyles.mr1}
                >
                    Agree
                </Button>
                <Button
                    onClick={() => {
                        if (window?.parent && document?.referrer) window.parent.postMessage({ close: true }, document.referrer);
                        else if (window?.parent) window.parent.postMessage({ close: true }, "*");
                    }}
                    sx={globalStyles.mr1}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    ) : (
        <Box sx={globalStyles.pa1}>
            <h4>Something went wrong.</h4>
            <h6>Please check your URL, it may be missing your company name!</h6>
        </Box>
    );
};

export default TermsAndConditions;
