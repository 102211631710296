import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import RIAForm from "../molecules/RIAForm";
import globalStyles from "../../globalStyles";
import { AppDispatch } from "../../configureStore";
import { editCustomer } from "../../redux/reducer/borrowers";

interface EditDialogParams {
    open: boolean;
    handleClose: () => void;
}

const EditDialog = ({
    open,
    handleClose,
}: EditDialogParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const onSubmit = (
        form: {
            firstName: string;
            lastName: string;
            email: string;
            id?: string | undefined;
        },
        _r?: boolean,
        _ce?: boolean,
        onDone?: () => void,
    ) => {
        dispatch(editCustomer({
            input: form,
            onSuccess: () => {
                handleClose();
                onDone && onDone();
            },
        }));
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose();
            }}
            aria-labelledby="edit-modal-title"
            aria-describedby="edit-modal-description"
        >
            <DialogTitle id="edit-dialog-title">Edit Customer</DialogTitle>
            <DialogActions sx={globalStyles.flexCenterEnd}>
                <RIAForm
                    onSubmit={onSubmit}
                    isEdit
                    onClose={handleClose}
                />
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
