// eslint-disable-next-line import/no-cycle
import { ERROR_MESSAGE } from "../reducer/status";

/* eslint-disable no-param-reassign */
const failure = (dispatch: any, type: string, input: any, message?: string) => (payload: any) => {
    try {
        if (payload.message.startsWith('Request failed')) {
            dispatch({
                type,
                ...input,
                data: payload.message,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: message ?? 'Something went wrong!',
            });
        } else {
            const parsed = JSON.parse(payload.message);

            dispatch({
                type,
                ...input,
                data: parsed?.data,
            });

            dispatch({
                type: ERROR_MESSAGE,
                message: parsed?.data?.responseData ?? message ?? 'Something went wrong!',
            });

            if (input?.onError) input.onError(parsed);
        }
    } catch {
        dispatch({
            type,
            ...input,
            data: payload?.data,
        });

        dispatch({
            type: ERROR_MESSAGE,
            message: message ?? 'Something went wrong!',
        });

        if (input?.onError) input.onError(payload);
    }
};

export default failure;
