import * as Icons from "@mui/icons-material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { IconButton, IconButtonProps, IconProps } from "@mui/material";

export interface IconParams extends IconProps {
    name: string;
    iconButton?: IconButtonProps;
    onClick?: (...any: any) => any;
}

const Icon = ({
    name,
    onClick,
    iconButton,
    ...props
}: IconParams) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const Component = Icons[name] ?? HelpOutlineOutlinedIcon;
    if (onClick) {
        return (
            <IconButton onClick={onClick} {...iconButton}>
                <Component {...props} />
            </IconButton>
        );
    }
    return (
        <Component {...props} />
    );
};

export default Icon;
