import { Box } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../atoms/Button";
import InvitationContainer from "../organisms/InvitationContainer";
import globalStyles from "../../globalStyles";
import InvitationModal from "../organisms/InvitationModal";
import { IInvite } from "../../utils/interface";
import { AppDispatch, RootState } from "../../configureStore";
import { inviteCustomer, resendInvite } from "../../redux/reducer/borrowers";

interface DashboardParams {
}

const Dashboard = ({
}: DashboardParams) => {
    const disabled = useSelector<RootState, boolean>(({ auth, encompass }) => ((!!auth?.institutionId && auth?.institutionId !== auth?.me?.institutionId) || (auth?.institution?.name ?? encompass.origin?.origin?.originatingParty?.name ?? '').length === 0));

    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const submit = (
        form: IInvite,
        isResend?: boolean,
        _?: boolean,
        onDone?: () => void,
    ) => {
        if (isResend) dispatch(resendInvite({ input: form, onSuccess: () => { setOpen(false); } }));
        else {
            dispatch(
                inviteCustomer({
                    input: form,
                    onSuccess: () => {
                        setOpen(false);
                        onDone && onDone();
                    },
                }),
            );
        }
    };

    return (
        <Box sx={[globalStyles.w100, globalStyles.flexStart, globalStyles.col, globalStyles.pb2]}>
            <InvitationModal
                open={open}
                handleClose={() => { setOpen(false); }}
                onSubmit={submit}
            />
            <Button
                sx={[globalStyles.my2, { justifySelf: 'flex-start' }]}
                variant="contained"
                onClick={() => { setOpen(true); }}
                disabled={disabled}
            >
                Invite Client
            </Button>
            <InvitationContainer />
        </Box>
    );
};

export default Dashboard;
